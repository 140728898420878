import toast from "react-hot-toast";

interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

const text503 = "Noen av tjenestene er midlertidige utilgjengelige grunnet vedlikehold eller høy trafikk. Vennligst prøv igjen om noen minutter. Beklager for ulempen dette medfører."

export const handleFetchError = async (response: HttpResponse<unknown>, url?: string, tekst?: string) => {
    if (!response) throw new Error(tekst || "Noe gikk galt")
    let txt = ""
    if (response.bodyUsed) {
        txt = response.parsedBody as string
    } else
        txt = await response.text();
    try {
        if (txt) {

            let responseContent = await parseError(txt)
            if (responseContent === "" && response.statusText) {
                responseContent = response.statusText
            }
            if (responseContent === "") {
                responseContent = await handleResponseStatus(response)
            }
            throw new Error(responseContent + " " + url)
        } else {
            throw new Error(response?.statusText || await handleResponseStatus(response) + " " + url)
        }
    } catch (error) {
        var event = new CustomEvent("byggsokEvent", {
            detail: error
        });
        document.dispatchEvent(event);
    }

}
export const handleFetchErrorV2 = async (response: HttpResponse<unknown>): Promise<string> => {

    if (!response || response.status === 503) return text503
    if(response.status === 401) return null
    let txt = ""
    if (response.bodyUsed) {
        txt = response.parsedBody as string
    } else
        txt = await response.text();
    try {
        if (txt) {

            let responseContent = await parseError(txt)
            if (responseContent === "" && response.statusText) {
                responseContent = response.statusText
            }
            if (responseContent === "") {
                responseContent = await handleResponseStatus(response)
            }
            return response.url + "\n" + responseContent

        } else {
            return response.url + "\n" + (response?.statusText || await handleResponseStatus(response))
        }
    } catch (error) {
        var event = new CustomEvent("byggsokEvent", {
            detail: error
        });
        document.dispatchEvent(event);
    }

}

const handleResponseStatus = async (response: HttpResponse<unknown>) => {
    switch (response.status) {
        case 400:
            return `400 - Ugyldig forespørsel`
        case 401:
            return `401 - Ikke autentisert`
        case 403:
            return `403 - Ikke tilgang`
        case 404:
            return `404 - Fant ikke`
        case 500:
            return `500 - Ukjent feil`
        case 503:
            return `503 - Server ikke tilgjengelig`
        default:
            return `${response.status}`
    }
}
export const parseErrorAndToast = async (msg: string | any) => {
    let str = await parseError(msg)
    if (str == undefined || str == "" || str == "{}")
        str = text503
    toast.error(str, { id: str })
}
export const parseError = async (msg: string | any): Promise<string> => {
    let responseContent = msg || ""
    try {
        let validJson = false
        try {
            JSON.parse(msg)
            validJson = true
        } catch { }
        const parsed = validJson ? JSON.parse(msg) : msg
        if (typeof parsed === "string") return parsed
        if (parsed.hasOwnProperty("Title")) responseContent = parsed["Title"] //Feks matrikkelController returnerer NotFound med title i JSON
        if (parsed.hasOwnProperty("Message")) responseContent = parsed["Message"] //Typisk MaksSok WebApi return
        if (parsed.hasOwnProperty("ExceptionMessage")) responseContent = parsed["ExceptionMessage"] //Typisk MaksSok WebApi return
        if (parsed.hasOwnProperty("ComponentStack")) responseContent = parsed["ComponentStack"]
    } catch { }
    return responseContent
}