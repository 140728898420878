import { useLocation, useParams } from "react-router-dom"
import { AbHubProject } from "../../interfaces/AbHubTypes"
import { server } from "../../legacy/interfaces/types"
import { Altinn } from "../../interfaces/SharedTypes"
import { SoknadType } from "../../interfaces/models/soknad/SoknadTypes"
export type AttachmentMetaKey = [{
    scope: string
    serviceCode: number,
    serviceCodeEdition: number
}]
export type BaseQueryKey = [{
    scope: string
}]

export type GeoNorgeQueryKey = [{
    scope: "GeoNorge",
    area: string
    register: string
    subregister?: string
}]
export type CurrentUserCompanyKey = [{
    scope: "currentUserCompany",
    orgnr: string
}]
export type NasjonaleSjekklisterKey = [{
    scope: "NasjonaleSjekklister"
    tiltak: string[]
}]

export type ArbeidstilsynetsSjekklisteKey = [{
    scope: string
    ttParams: string
}]
export type ProjectDetailsKey = [{
    scope: string
    prosjektId: string
}]
export type GjennomforingsplanNodeIdKey = [{
    scope: string
    subScope: string
    prosjektId: string
}]
export type AttachmentListKey = [{
    scope: string
    subScope: string
    nodeId: string
}]
export type AttachmentListV2Key = [{
    scope: string
    subScope: string
    prosjektId: string
    prefix: string
}]
export type SignedAnsvarsrettsKey = [{
    scope: string
    nodeId: string
}]
export type FtbBackendValidationKey = [{
    scope: string
    subScope: string
    soknadType: string
    prosjektId: string
    version: string
    attachmentTypes?: string[]
}]
export type FtbValidationModelKey = [{
    scope: string
    subScope: string
    type: string
    nodeId: string
}]
export type FtbValidationKey = [{
    scope: string
    subScope: string
    hash: string
}]
export type SoknadValidationKey = [{
    scope: string
    subScope: string
    type: string
    nodeId: string
}]
export type SoknadVersionKey = [{
    scope: string
    soknadType: string
    prosjektId: string,
    version: string
}]
export type SoknadStatusKey = [{
    scope: string
    subScope: string
    soknadType: string
    prosjektId: string,
    version: string
}]
export type SoknadViewModelKey = [{
    scope: string
    prosjektId: string,
    nid: string
}]
export type SoknadMetaKey = [{
    scope: "SoknadMetaData"
    nodeId: string
}]
export type SoknadSubskjemaKey = [{
    scope: "SoknadSubskjema"
    nodeId: string
}]
export type UseNabovarselAndMerknaderKey = [{
    scope: "UseNabovarselAndMerknader"
    nodeId: string
}]
export type AltinnReporteeKey = [{
    scope: "altinn"
    subScope: "altinnReportee"
    reportee: string
}]
export type AltinnRoleKey = [{
    scope: "altinn"
    subScope: "altinnRole"
    firmId: string
    reportee: string
}]
export type AltinnDelegationKey = [{
    scope: "altinn"
    subScope: "altinnDelegation"
    firmId: string
}]
export type AltinnMessageKey = [{
    scope: "altinn"
    subScope: "message"
    reportee: string
    arCode: string
}]
export type SentNabovarselNodeListKey = [{
    scope: "nabovarsel"
    soknadNodeId: string
}]
export type SentNabovarselKey = [{
    scope: "nabovarsel"
    sentNabovarselNodeId: string
}]
export type NabovarselDetailsKey = [{
    scope: "nabovarselDetails"
    prosjektId: string
}]
export type ProsjektGuidKey = [{
    scope: "project"
    guid: string
}]
export type LatestProjectsKey = [{
    scope: "project"
    subScope: string
    prosjektId: string
}]
export type AllCompaniesKey = [{
    scope: "project",
    subScope: "company"
}]
export type CompaniesInProjectKey = [{
    scope: "project",
    subScope: "company",
    prosjektId: string
}]
export type CompaniesKey = [{
    scope: "project"
    subScope: "company"
    prosjektId: string
}]
export type CompanyKey = [{
    scope: "project"
    subScope: "company"
    prosjektId: string
    foretakId: string
}]
export type AnsvarsomraadeListKey = [{
    scope: "project"
    subScope: "ansvarsomraade"
    prosjektId: string
}]
export type AnsvarsomraadeKey = [{
    scope: "project"
    subScope: "ansvarsomraade"
    prosjektId: string
    ansvarsomraadeId: string
}]
export type SentralGodkjenningKey = [{
    scope: "sentralgodkjenning"
    orgnr: string
}]
export type TiltakshaversSamtykkeStatusKey = [{
    scope: "project"
    subScope: "tiltakshaverssamtykke"
    type: string
    prosjektId: string
    
}]
export type GjennomoringsplanVersionKey = [{
    scope: "project"
    subScope: "gjennomføringsplanversion"
    prosjektId: string
    gjplanNodeId: string
}]
export type AnsvarSokKey = [{
    scope: "project"
    subScope: "ansvarsok"
    prosjektId: string
}]
export type GjennomoringsplanPrevVersionsKey = [{
    scope: "project"
    subScope: "gjennomføringsplanprevversions"
    prosjektId: string
    gjplanNodeId: string
}]
export type AltinnInboxKey = [{
    scope: "altinn"
    subScope: "inbox"
    firmId: string
    filter: Altinn.Common.MessageSettings
}]
export type SoknadV2ModelKey = [{
    scope: "soknadv2"
    subscope: "model"
    prosjektId: string
    formId: string

}]
export type SoknadV2ViewModelListKey = [{
    scope: "soknadv2"
    subscope: string
    prosjektId: string
    compound?: "soknadTransaction"
}]
export type FileApiModelKey = [{
    scope: "soknadv2"
    subscope: "files"
    prosjektId: string
    formId: string

}]
export type TransactionKey = [{
    scope: "transaction"
    prosjektId: string
    compound?: "soknadTransaction" | "vedtakTransaction"
}]
export type VedtakListKey = [{
    scope: "vedtak"
    prosjektId: string
    compound: "vedtakTransaction"
}]
export type VedtakKey = [{
    scope: "vedtak"
    prosjektId: string
    vedtakId: string
    compound: "vedtakTransaction"
}]
export type Altinn3AttachmentMetaKey = [{
    scope: string
    appName: string,
}]
export type ProjectTransitionMetaKey = [{
    scope: "projectTransitionMeta"
    prosjektId: string
}]
export const soknadNameToSoknadType = (soknadName: string) => {
    switch (soknadName.toLowerCase()) {
        case "rammesoknad":
            return "RS";
        case "ettrinn":
            return "ET";
        case "ig":
            return "IG";
        case "mb":
            return "MB";
        case "ferdigattest":
            return "FA";
        case "endringssoknad":
            return "ES";

    }
}
export const createGeoNorgeQueryKey = (area: string, register: string, subregister?: string): GeoNorgeQueryKey => {
    return [{
        scope: "GeoNorge",
        area,
        register,
        subregister
    }]
}
export const createCurrentUserCompanyKey = (orgnr: string): CurrentUserCompanyKey => {
    return [{
        scope: "currentUserCompany",
        orgnr
    }]
}
export const createNasjonaleSjekklisterKey = (tiltak: string[]): NasjonaleSjekklisterKey => {
    return [{
        scope: "NasjonaleSjekklister",
        tiltak,
    }]
}

export const createAttachmentMetaQueryKey = (serviceCode: number, serviceCodeEdition: number): AttachmentMetaKey => {
    return [{
        scope: "AttachmentMetaKey",
        serviceCode,
        serviceCodeEdition
    }]
}
export const createArbeidstilsynetsSjekklisteKey = (tiltaksliste: AbHubProject.TiltakType[]) : ArbeidstilsynetsSjekklisteKey => {
    return [{
        scope: "ArbeidstilsynetSjekklisteKey",
        ttParams: tiltaksliste?.map(x => x.description).join(",")
    }]
}
export const createProjectDetailsKey = (prosjektId: string) : ProjectDetailsKey => {
    return [{
        scope: "projectDetails",
        prosjektId
    }]
}
export const createProjectDetailsV2Key = (prosjektId: string) : ProjectDetailsKey => {
    return [{
        scope: "projectDetailsV2",
        prosjektId
    }]
}
export const createGjPlanNodeIdKey = (prosjektId: string) : GjennomforingsplanNodeIdKey => {
    return [{
        scope: "gjennomforingsplan",
        subScope: "nodeId",
        prosjektId
    }]
}
export const createProjectMetaKey = (prosjektId: string) : ProjectDetailsKey => {
    return [{
        scope: "projectMeta",
        prosjektId
    }]
}
export const createFtbBackendValidationKey = (soknadType: string, prosjektId: string, version: string, attachmentTypes?: string[]) : FtbBackendValidationKey => {
    return [{
        scope: "soknad",
        subScope:"validation",
        soknadType,
        prosjektId,
        version,
        attachmentTypes: attachmentTypes || []
    }]  

}
export const createFtbValidationModelKey = (nodeId: string) : FtbValidationModelKey => {
    return [{
        scope: "soknad",
        subScope:"validation",
        type: "ftbmodel",
        nodeId
    }]  

}
export const createFtbValidationKey = (md5: string) : FtbValidationKey => {
    return [{
        scope: "soknad",
        subScope:"ftbvalidation",
        hash: md5
    }]  

}
export const createSoknadValidationKey = (nodeId: string) : SoknadValidationKey => {
    return [{
        scope: "soknad",
        subScope:"validation",
        type: "soknadvalidation",
        nodeId
    }]  

}
export const createSoknadVersionKey = (soknadType: string, prosjektId: string, version: string) : SoknadVersionKey => {
    return [{
        scope: "soknad",
        soknadType: soknadType,
        prosjektId,
        version
    }]
}
export const createSoknadStatusKey = (soknadType: string, prosjektId: string, version: string) : SoknadStatusKey => {
    return [{
        scope: "soknad",
        subScope: "status",
        soknadType: soknadType,
        prosjektId,
        version
    }]
}
export const createSoknadViewModelKey = (prosjektId: string, nid: string) : SoknadViewModelKey => {
    return [{
        scope: "soknad",
        prosjektId,
        nid
    }]
}
export const createAttachmentListKey = (nodeId: string) : AttachmentListKey => {
    return [{
        scope: "soknad",
        subScope: "attachments",
        nodeId
    }]
}
export const createAttachmentListV2Key = (prosjektId: string, prefix: string) : AttachmentListV2Key => {
    return [{
        scope: "soknad",
        subScope: "attachments",
        prosjektId,
        prefix
    }]
}
export const createSoknadMetaKey = (nodeId: string) : SoknadMetaKey => {
    return [{
        scope: "SoknadMetaData",
        nodeId
    }]
}
export const createSignedAnsvarsrettKey = (nodeId: string) : SignedAnsvarsrettsKey => {
    return [{
        scope: "SignedAnsvarsrettList",
        nodeId
    }]
}
export const createUseNabovarselVedleggKey = (nodeId: string) : UseNabovarselAndMerknaderKey => {
    return [{
        scope: "UseNabovarselAndMerknader",
        nodeId
    }]
}
export const createAltinnReporteeKey = (reportee: string) : AltinnReporteeKey => {
    return [{
        scope: "altinn",
        subScope: "altinnReportee",
        reportee
    }]
}
export const createAltinnRoleKey = (reportee: string, firmId: string) : AltinnRoleKey => {
    return [{
        scope: "altinn",
        subScope:"altinnRole",
        reportee,
        firmId
    }]
}

export const createAltinnDelegationKey = (firmId: string) : AltinnDelegationKey => {
    return [{
        scope: "altinn",
        subScope: "altinnDelegation",
        firmId
    }]
}
export const createAltinnMessageKey = (reportee: string, arCode: string) : AltinnMessageKey => {
    return [{
        scope: "altinn",
        subScope: "message",
        reportee,
        arCode
    }]
}
export const createSentNabovarselOnSoknadKey = (soknadNodeId: string) : SentNabovarselNodeListKey => {
    return [{
        scope: "nabovarsel",
        soknadNodeId
    }]
}
export const createSentNabovarselKey = (sentNabovarselNodeId: string) : SentNabovarselKey => {
    return [{
        scope: "nabovarsel",
        sentNabovarselNodeId
    }]
}
export const createNabovarselDetailsKey = (prosjektId: string) : NabovarselDetailsKey => {
    return [{
        scope: "nabovarselDetails",
        prosjektId
    }]
}
export const createProsjekGuidKey = (guid: string) : ProsjektGuidKey => {
    return [{
        scope: "project",
        guid
    }]
}
export const createLatestProjectsKey = (prosjektId: string) : LatestProjectsKey => {
    return [{
        scope: "project",
        prosjektId,
        subScope: "getMyLatestsProjects"
    }]  
}
export const createAllCompaniesKey = () : AllCompaniesKey => {
    return [{
        scope: "project",
        subScope: "company",
    }]
}
export const createCompaniesInProjectKey = (prosjektId: string) : CompaniesInProjectKey => {
    return [{
        scope: "project",
        subScope: "company",
        prosjektId
    }]
}
export const createCompaniesKey = (prosjektId: string) : CompaniesKey => {
    return [{
        scope: "project",
        subScope: "company",
        prosjektId
    }]
}
export const createCompanyKey = (prosjektId: string, foretakId: string) : CompanyKey => {
    return [{
        scope: "project",
        subScope: "company",
        prosjektId,
        foretakId
    }]
}
export const createAnsvarsomraadeListKey = (prosjektId: string) : AnsvarsomraadeListKey => {
    return [{
        scope: "project",
        subScope: "ansvarsomraade",
        prosjektId
    }]
}
export const createAnsvarsomraadeKey = (prosjektId: string, ansvarsomraadeId: string) : AnsvarsomraadeKey => {
    return [{
        scope: "project",
        subScope: "ansvarsomraade",
        prosjektId,
        ansvarsomraadeId
    }]
}
export const createSentralGodkjenningKey = (orgnr: string) : SentralGodkjenningKey => {
    return [{
        scope: "sentralgodkjenning",
        orgnr
    }]
}
export const createSoknadSubskjemaKey = (nodeId: string) : SoknadSubskjemaKey => {
    return [{
        scope: "SoknadSubskjema",
        nodeId
    }]
}
export const createTiltakshaversSamtykkeSentStatusKey = (prosjektId: string) : TiltakshaversSamtykkeStatusKey => {
    return [{
        scope: "project",
        subScope: "tiltakshaverssamtykke",
        type:"sent",
        prosjektId
    }]
}
export const createTiltakshaversSamtykkeSignedStatusKey = (prosjektId: string) : TiltakshaversSamtykkeStatusKey => {
    return [{
        scope: "project",
        subScope: "tiltakshaverssamtykke",
        type: "signed",
        prosjektId
    }]
}
export const createGjplanVersionKey = (prosjektId: string, gjplanNodeId: string) : GjennomoringsplanVersionKey => {
    return [{
        scope: "project",
        subScope: "gjennomføringsplanversion",
        prosjektId,
        gjplanNodeId
    }]
}
export const createAnvarSokKey = (prosjektId: string) : AnsvarSokKey => {
    return [{
        scope: "project",
        subScope: "ansvarsok",
        prosjektId
    }]
}
export const createGjplanPrevVersionsKey = (prosjektId: string, gjplanNodeId: string) : GjennomoringsplanPrevVersionsKey => {
    return [{
        scope: "project",
        subScope: "gjennomføringsplanprevversions",
        prosjektId,
        gjplanNodeId
    }]
}

export const createAltinnInboxKey = (firmId: string, filter: Altinn.Common.MessageSettings) : AltinnInboxKey => {
    return [{
        scope: "altinn",
        subScope: "inbox",
        firmId,
        filter
    }]
}

export const createSoknadV2ModelKey = (prosjektId: string, formId: string) : SoknadV2ModelKey => {
    return [{
        scope: "soknadv2",
        subscope: "model",
        prosjektId,
        formId
    }]
}
export const createSoknadV2ViewModelListKey = (prosjektId: string, soknadtype: SoknadType) : SoknadV2ViewModelListKey => {
    return [{
        scope: "soknadv2",
        subscope: soknadtype,
        prosjektId,
        compound: "soknadTransaction"
    }]
}
export const createFileApiKey = (prosjektId: string, formId: string) : FileApiModelKey => {
    return [{
        scope: "soknadv2",
        subscope: "files",
        prosjektId,
        formId
    }]
}
export const createTransactionKey = (prosjektId: string, compound: "soknadTransaction"|"vedtakTransaction" = "soknadTransaction") : TransactionKey => {
    return [{
        scope: "transaction",
        prosjektId,
        compound: compound
    }]
}

export const createSoknadTransactionKey = (prosjektId: string) => {
    return [{
        prosjektId,
        compound: "soknadTransaction"
    }]
}
export const createVedtakListKey = (prosjektId: string) : VedtakListKey => {
    return [{
        scope: "vedtak",
        prosjektId,
        compound: "vedtakTransaction"
    }]
}
export const createVedtakKey = (prosjektId: string, vedtakId: string) : VedtakKey => {
    return [{
        scope: "vedtak",
        prosjektId,
        vedtakId,
        compound: "vedtakTransaction"
    }]
}

export const createInvalidateVedtakKey = (prosjektId: string) : any => {
    return [{
        scope: "vedtak",
        prosjektId,
        compound: "vedtakTransaction"
    }]
}

export const createAltinn3AttachmentMetaQueryKey = (appName: string): Altinn3AttachmentMetaKey => {
    return [{
        scope: "Altinn3AttachmentMetaKey",
        appName,
    }]
}
export const createProjectTransitionMetaKey = (prosjektId: string): ProjectTransitionMetaKey => {
    return [{
        scope: "projectTransitionMeta",
        prosjektId
    }]
}
