import { Altinn, Altinn3 } from "../interfaces/SharedTypes";
import { hash } from "../utils/Utils";
import { default as LegacyApi } from "../legacy/stores/Api";
import { AltinnSendFileUpload } from "../interfaces/models/altinn/AltinnSendEvent";
import { CleanFilename } from "../utils/TextUtils";
import { activeUser, altinnUserManager, userFirmAccess, userManager } from "../Auth";
import toast from "react-hot-toast";
import DateUtils from "../utils/DateUtils";
interface Headers {
    [key: string]: string
}
class Altinn3Api {
    
    server: string
    defaultHeaders: Headers
    constructor() {
        this.defaultHeaders ={
            "Accept": "application/hal+json"
        }
        this.server = process.env.REACT_APP_ALTINN_3_SERVER
    }
    private _AltinnApi = async (uri: string, method?: "GET" | "POST" | "PUT" | "DELETE", postData?: any, extraHeaders?: Headers, annonymous: boolean = false): Promise<Response> => {
        let _headers = {...this.defaultHeaders}
        const ainUser = await activeUser()
        _headers.Authorization = "Bearer " + ainUser.access_token
        if(annonymous === false) {
            const altinnToken = await altinnUserManager?.getUser()
            if(altinnToken?.expired) {
                const user = await altinnUserManager?.signinSilent()
                if(!user) altinnUserManager.signoutSilent()
            }
            if(!altinnToken?.access_token) throw Error("Altinn token not found")
            _headers["x-idporten-token"] = altinnToken.access_token
        }
        
        const _method = method || "GET"
        if (extraHeaders)
            _headers = Object.assign(_headers, extraHeaders)
        const response = await fetch(this.server+"/api/"+uri, {
            headers: _headers,
            method: _method,
            body: postData ? postData : null
        })
        if (!response?.ok) {
            if(response?.status == 401) {
                toast.error("Altinn token er utløpt. Du må logge inn på nytt om du skal sende søknader.")
                await altinnUserManager.removeUser()
            }else
                return await this.InspectFetchApiError(response, uri, method, postData, extraHeaders)

        }
        if(response?.headers?.has("X-Warning-LimitReached")){
            toast("Filtrering av Altinn-innboksen har nådd grensen for antall meldinger. Vennligst filtrer på en mindre tidsperiode eller søk etter en spesifikk søknadstype for å se flere meldinger.")
        }
        return response

    }
    private _Prefix = async (server: string) => {
        const ainUser = await activeUser()
        const fc = userFirmAccess(ainUser)
        return server+"/"+fc.CustomerExternalId
    }
    public GetAltinnMetadata = async (appId: string) => {
        const uri = `${await this._Prefix("altinn-app")}/${appId}/metadata`
        const response = await this._AltinnApi(uri)
        const appMeta =  await response.json() as Altinn3.AppMeta
        return appMeta.metadata
    }
    public GetApps = async () => {
        const uri = `${await this._Prefix("altinn-app")}`
        const response = await this._AltinnApi(uri)
        const appMeta =  await response.json() as Altinn3.App.AltinnApp[]
        return appMeta
    }
    public GetApp = async (appId: string) => {
        const uri = `${await this._Prefix("altinn-app")}/${appId}`
        const response = await this._AltinnApi(uri)
        const appMeta =  await response.json() as Altinn3.App.AltinnApp
        return appMeta
    }
    public GetExchangeToken = async () => {
        const uri = `${await this._Prefix("altinn")}/exchange-token`
        const response = await this._AltinnApi(uri)
        const exchangeToken =  await response.text()
        return exchangeToken
    }
    public CreateInstance = async (appId: string, formData: FormData) : Promise<Altinn3.Instance.Instance> => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances`
        const response = await this._AltinnApi(uri, "POST", formData)
        const instance =  await response.json()
        return instance as Altinn3.Instance.Instance
    }
    public GetInstances = async (appId: string, instanceOwnerPartyId: number) : Promise<Altinn3.Instance.InstanceListElement[]> => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances${instanceOwnerPartyId ? "/"+instanceOwnerPartyId : ""}`
        const response = await this._AltinnApi(uri, "GET")
        const instance =  await response.json()
        return instance
    }
    public GetInstance = async (appId: string, instanceGuid?: string) => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances${instanceGuid ? "/"+instanceGuid : ""}`
        const response = await this._AltinnApi(uri)
        const instance =  await response.json()
        return instance
    }
    public ValidatInstance = async (appId: string, instanceId: string) : Promise<Altinn3.Instance.ValidationElement[]> => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances/${instanceId+"/validate"}`
        const response = await this._AltinnApi(uri)
        const validationResult =  await response.json()
        return validationResult
    }
    public DeleteInstance = async (appId: string, instanceId: string) => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances/${instanceId}`
        const response = await this._AltinnApi(uri, "DELETE")
        return response
    }
    public GetParties = async (appId: string) : Promise<Altinn3.App.Party[]> => {
        const uri = `${await this._Prefix("altinn")}/${appId}/parties`
        const response = await this._AltinnApi(uri)
        const party =  await response.json()
        return party
    }
    public NextProcess = async (appId: string, instanceGuid: string, payLoad: any) => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances/${instanceGuid}/process/next`
        const response = await this._AltinnApi(uri, "PUT", payLoad, {"Content-Type": "application/json"})
        return await response.json()
    }
    public UploadAttachment = async (appId: string, instanceGuid: string, dataType: string, formData: FormData) : Promise<Altinn3.Instance.Data> => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances/${instanceGuid}/attachment/${dataType}`
        const response = await this._AltinnApi(uri, "POST", formData)
        return await response.json()
    }
    public CreateDataElement = async (appId: string, instanceGuid: string, dataType: string, formData: FormData) => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances/${instanceGuid}/data/${dataType}`
        const response = await this._AltinnApi(uri, "POST", formData)
        return await response.json()
    }
    public UpdateDataElement = async (appId: string, instanceOwnerPartyId: string, instanceGuid: string, dataGuid: string, formData: FormData) => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances/${instanceOwnerPartyId}/${instanceGuid}/data/${dataGuid}`
        const response = await this._AltinnApi(uri, "PUT", formData)
        return await response.json()
    }
    public DeleteDataElement = async (appId: string, instanceOwnerPartyId: string, instanceGuid: string, dataGuid: string) => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances/${instanceOwnerPartyId}/${instanceGuid}/data/${dataGuid}`
        const response = await this._AltinnApi(uri, "DELETE")
        return await response.json()
    }
    public PdfPreview = async (appId: string, instanceGuid: string, dataGuid: string) => {
        const uri = `${await this._Prefix("altinn")}/${appId}/instances/${instanceGuid}/data/${dataGuid}/pdfpreview`
        const response = await this._AltinnApi(uri, "GET", null, {
            ResponseType: "blob", 
            Accept: "application/pdf" 
        })
        return await response.blob()
    }
    InspectFetchApiError = async (response: Response, uri: string, method?: string, postData?: any, extraHeaders?: Headers) => {
        if (!response) {
            throw new Error("En feil har skjedd mot Altinn. Det kan hende du må logge inn på nytt.")
        }
        let errorObj: Altinn.Common.AltinnCustomException = {
            objectId: "altinnCustomException",
            status: response.status,
            statusText: response.statusText || "",
            statusTextDetails: null,
            statusTextDetailsIsArray: false
        }
        const text = await response.text()
        let json = null
        try {
            json = JSON.parse(text)

        } catch { }
        if (json != null) {
            const hasValidationErrors = json.hasOwnProperty("ValidationErrors")
            if (hasValidationErrors) {
                errorObj.statusTextDetailsIsArray = Array.isArray(json.ValidationErrors)
                errorObj.statusTextDetails = json.ValidationErrors
            } else
                errorObj.statusTextDetails = JSON.stringify(json, null, 2)
        }
        throw errorObj
    }

}
export default Altinn3Api
