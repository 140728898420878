import 'bootstrap/dist/css/bootstrap.min.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import ErrorBoundaryTopLevel from './components/ErrorBoundaryTopLevel';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import $ from 'jquery';

declare global {
  interface Window {
    $: any;
  }
}
window.$ = $;
const appRoot = createRoot(document.getElementById('root'))
appRoot.render(
  <ErrorBoundaryTopLevel>
    <App />
  </ErrorBoundaryTopLevel>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


