import { decorate, observable, runInAction } from "mobx";
import { Ab } from "../../interfaces/abTypes";
import { Altinn } from "../../interfaces/SharedTypes";
import { server } from "../interfaces/types";
import { IHubLogger } from "../utilities/AB-Hub/Files/IHubLogger";
import { Context } from "./Context";
import TabStore from "./TabStore";


class AppStore_ {
    envVariables: server.AppEnv
    loggedinUser: server.User
    context: Context
    logger: IHubLogger
    tabStore: TabStore
    altinnSettings: Altinn.Common.Settings
    constructor() {
        this.context = new Context();
    }
    
    user(): server.User {
        return this.loggedinUser
    }
    get prodEnv(): boolean { return this.envVariables?.prodEnv }
    get altinnUrl(): string { return this.envVariables?.altinnPath }
    get env(): server.AppEnv { return this.envVariables }

    get ctx() {
        return this.context
    }
    // get commonData(): server.SoknadCommon {
    //     return this.context.sok.commonData
    // }

    // set commonData(data: server.SoknadCommon) {
    //     this.context.sok.setCommonData(data)
    // }
    setUser(abUser: Ab.User) {
        if (abUser)
            runInAction(() => {
                this.loggedinUser = {
                    CompanyName: abUser.firm,
                    CompanyType: "",
                    Name: abUser.firstname + " " + abUser.lastname,
                    OrgNr: abUser.firmid,
                    Username: abUser.username,
                    SentralGodkjenning: false
                }
            })
    }
}
decorate(AppStore_, {
    loggedinUser: observable,
    context: observable
})
const AppStore = new AppStore_();
export default AppStore