import Api from "../../Api";
import { getUrlParameter, hash } from "../../../utilities/utilityFunc";
import { altinn } from "../../../interfaces/types";
import AltinnStoreV2 from "../altinnStoreV2";
import AppStore from "../../AppStore";
import { toJS } from "mobx";
import InnsendingsprosessStore from "../../InnsendingsStore";
import LogEmailService from "../../../utilities/LogEmailService";
import { Altinn } from "../../../../interfaces/SharedTypes";
import { altinnUserManager } from "../../../../Auth";

interface AltinnLoginPath {
    path: string;
}
interface Headers {
    [key: string]: string
}
const BuildUrlBase = (uri: string): string => {
    let url = ""
    const _settings = JSON.parse(JSON.stringify(AltinnStoreV2.settings));
    if (uri.startsWith(_settings.apiBaseUrl)) url = uri
    else url = _settings.apiBaseUrl + "/api" + uri
    return url
}

const AltinnApi = async (uri: string, method?: "GET" | "POST" | "PUT" | "DELETE", postData?: any, extraHeaders?: Headers): Promise<Response> => {
        const _settings = JSON.parse(JSON.stringify(AltinnStoreV2.settings));
        let _headers = _settings.headers
        const altinnToken = await altinnUserManager?.getUser()
        if(altinnToken?.expired) {
            const user = await altinnUserManager?.signinSilent()
            if(!user) altinnUserManager.signoutSilent()
        }
        if(!altinnToken?.access_token) throw Error("Altinn token not found")
        _headers.Authorization = "Bearer " + altinnToken.access_token
        const url = BuildUrlBase(uri)
        const _method = method || "GET"
        if (extraHeaders)
            _headers = Object.assign(_headers, extraHeaders)
        const response = await fetch(url, {
            headers: _headers,
            method: _method,
            body: postData ? postData : null
        })
        if(!response?.ok) {
            return await InspectFetchApiError(response)

        }
        return response

}

export const LogInIdPort = () => {
    const ApiBaseUri = AppStore.altinnUrl;
    let currentUrl = window.location.href;
    let encodedReturnUrl = encodeURIComponent("returnUrl=".concat(currentUrl));
    let url = ApiBaseUri + "/Pages/ExternalAuthentication/Redirect.aspx?".concat(encodedReturnUrl);
    window.location.href = url;

}

/**
 * This function is tested.
 * Retreives a list of Reportees, based on ID Porten login cookie.
 * The logged in user can represent each of these entities in the resulting list
 */
export const AltinnGetReportees = async (): Promise<altinn.ReporteeResult> => {
    return await (await AltinnApi("/reportees?showConsentReportees=false&includeInactiveReportees=false&$top=100")).json()
}
export const AltinnGetRoles = async (): Promise<altinn.RoleResult> => {
    return await (await AltinnApi("/" + AppStore.user().OrgNr + "/authorization/roles")).json()
}
export const AltinnGetRolesForReportee = async (reporteeHref: string): Promise<altinn.RoleResult> => {
    return await (await AltinnApi(reporteeHref)).json()
}
export const AltinnGetRight = async (serviceCode: number, serviceEditionCode: number): Promise<altinn.ReporteeResult> => {
    return await (await AltinnApi(`/reportees?serviceCode=${serviceCode}&serviceEdition=${serviceEditionCode}&showConsentReportees=false&includeInactiveReportees=false`)).json()
}
export const AltinnGetPrint = async (printUrl: string): Promise<Blob> => {
    return await (await AltinnApi(`${printUrl}/print`, "GET", null, { ResponseType: "blob", Accept: "application/pdf" })).blob()
}
export const AltinnDeleteMessage = async (messageId: string): Promise<boolean> => {
    return await (await AltinnApi(messageId, "DELETE", null)).ok
}
export const AltinnGetForms = async (messageId: string): Promise<altinn.FormsResult> => {
    return await (await AltinnApi(`${messageId}/forms`, "GET")).json()
}
export const AltinnGetFormsAsXml = async (formsWrapper: altinn.FormsResult): Promise<XMLDocument[]> => {
    return Promise.all(formsWrapper._embedded.forms.map(f => AltinnGetForm(f._links.formdata.href)))
}
export const AltinnGetForm = async (formId: string): Promise<XMLDocument> => {
    const res = await AltinnApi(formId, "GET", null, { responseType: "application/xml" })
    return new window.DOMParser().parseFromString(await res.text(), "application/xml")
}
export const AltinnGetMessages = async (representing: string): Promise<altinn.MessageResult> => {
    const settings = AltinnStoreV2.settings.messages
    const filter = `?$top=${settings.pageSize}&$skip=${settings.skip}&$filter=ServiceOwner eq '${settings.serviceOwner}' and Type eq '${settings.messageType}' and Status eq '${settings.status}'`
    return await (await AltinnApi(`/${representing}/messages/${filter}`, "GET")).json()
}
export const AltinnGetMessagesV2 = async (representing: string, settings: Altinn.Common.MessageSettings): Promise<altinn.MessageResult> => {
    const filter = `?$top=${settings.pageSize}&$skip=${settings.skip}&$filter=ServiceOwner eq '${settings.serviceOwner}' and Type eq '${settings.messageType}' and Status eq '${settings.status}'`
    return await (await AltinnApi(`/${representing}/messages/${filter}`, "GET")).json()
}
export const AltinnInspectReportees = (reportees: altinn.ReporteeResult, orgnr: string): string => {
    try {
        const match = reportees._embedded.reportees.find(obj => obj.Type != "Person" && obj.OrganizationNumber == orgnr)
        return match.OrganizationNumber
    } catch (err) {
        throw err
    }
}
export const AltinnGetJson = async (uri: string): Promise<any> => {
    return await (await AltinnApi(uri, "GET")).json()
}
export const AltinnGetDelegations = async (): Promise<altinn.DelegationResult> => {
    return await (await AltinnApi("/" + AppStore.user().OrgNr + "/authorization/delegations")).json()
}
export const AltinnDeleteRole = async (reportee: string, roleId: number): Promise<Response> => {
    return await AltinnApi("/" + AppStore.user().OrgNr + "/authorization/delegations/"+reportee+"/roles/"+roleId, "DELETE", null)
}
export const AltinnAddRole = async (xml: string): Promise<Response> => {
    return await AltinnApi("/" + AppStore.user().OrgNr + "/authorization/delegations", "POST", xml, { "Content-Type": "application/xml"} )
}
const fillAltinnRightsWithNames = async (): Promise<altinn.FormNamesAndCodes[]> => {
    return new Promise((resolve, reject) => {
        Api.action("getAltinnRightsList").params().method("GET").data()
            .success((fromServer: altinn.FormNamesAndCodes[]) => resolve(fromServer))
            .error((err: Error) => reject(err))
    })
}
export const AltinnGetRights = async (): Promise<altinn.FormRight[]> => {

    let rightsList: altinn.FormRight[] = []
    const skjemaList = await fillAltinnRightsWithNames();
    if (skjemaList && Array.isArray(skjemaList)) {
        await Promise.all(skjemaList.map(async skjema => {
            try {
                const reporteeResult = await AltinnGetRight(skjema.serviceCode, skjema.serviceEditionCode);
                if (reporteeResult && reporteeResult._embedded) {
                    let newRight: altinn.FormRight = {
                        name: skjema.name,
                        hasRights: []
                    }
                    reporteeResult._embedded.reportees.map(r => {
                        if (r.Type.toLowerCase() == "person") {
                            // console.log("Processing", r)
                            newRight.hasRights.push({ name: r.Name, ident: r.SocialSecurityNumber ? hash(r.SocialSecurityNumber) : r.OrganizationNumber })
                        }

                    })
                    rightsList.push(newRight);
                }
            } catch (e) {
                rightsList.push({
                    name: skjema.name,
                    hasRights: [],
                    possibleErrorInObject: skjema
                })
            }

        }))
        return rightsList;
    }

}
export const AltinnPut = async (messageId: string, complete: boolean, sign: boolean, data: altinn.PostForm): Promise<string> => {
    let copy = Object.assign({}, data)
    delete copy._embedded;
    const res = await AltinnApi(`${messageId}?language=1044&complete=${complete}&sign=${sign}`, "PUT", JSON.stringify(copy), { "Content-Type": "application/hal+json; charset=utf-8" })
    if (res.status == 204) {
        var msg = res.headers.get('location');
        return msg;
    }
}
export const AltinnPost = async (reportee: string, complete: boolean, sign: boolean, data: altinn.PostForm): Promise<string> => {
    const res = await AltinnApi(`/${reportee}/messages?language=1044&complete=${complete}&sign=${sign}`, "POST", JSON.stringify(data), { "Content-Type": "application/hal+json; charset=utf-8" })
    if (res.status == 201) {
        var msg = res.headers.get('Location');
        return msg;
    }
    throw Error(res.statusText)
}
export const AltinnPostFile = async (reportee: string, messageId: string, filename: string, attachmentType: string, file: Blob, attachmentId: string, cancelAllToken?: boolean): Promise<boolean> => {
    if (cancelAllToken == true) throw Error("Opplasting avbrutt")
    try {
        var filenamelength = filename.length;
        if (filenamelength > 180) {
            var start = filenamelength - 180;
            filename = filename.substring(start);
        }

        let url = `/${reportee}/messages/${messageId}/attachments/streamedattachment?filename=${encodeURIComponent(filename)}&attachmenttype=${encodeURIComponent(attachmentType)}`
        url = BuildUrlBase(url)
        const altinnToken = await altinnUserManager?.getUser()
        if(!altinnToken?.access_token) throw Error("Altinn token not found")
        const _settings = JSON.parse(JSON.stringify(AltinnStoreV2.settings));
        let _headers = _settings.headers
        _headers = Object.assign(_headers, { 
            "Content-Type": "application/octet-stream", 
            "Authorization": "Bearer " + altinnToken?.access_token 
        })

        let customEvent = {
            id: attachmentId,
            name: filename,
            percentComplete: 0,
            error: null
        }
        InnsendingsprosessStore.prosess(customEvent);
        return new Promise((resolve, reject) => {
            $.ajax({
                type: "POST",
                url: url,
                headers: _headers,
                xhr: () => {
                    var xhr = new window.XMLHttpRequest();
                    xhr.upload.addEventListener("progress", (evt) => {
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            percentComplete = percentComplete * 100;
                            customEvent.percentComplete = percentComplete
                            InnsendingsprosessStore.prosess(customEvent);
                            // document.dispatchEvent(new CustomEvent("byggsokFileUploadEvent", { detail: customEvent }))
                        }
                    }, false);
    
                    return xhr;
                },
                processData: false,
                data: file,
                success: (data, status, jqHxr) => {
                    //LogEmailService.LogEvent(`Vedlegg lastet opp i Altinn ${filename} - ${attachmentId}`, true)
                    if (cancelAllToken) reject("Canceled upload in success")
                    resolve(true);
                },
                error: async (err) => {
                    reject(await InspectJqueryApiError(err))
                }
            })
        })
    } catch (err) {
        throw err
    }
}
export const InspectJqueryApiError = async (response: JQuery.jqXHR) : Promise<altinn.AltinnCustomException> => {
    let errorObj : altinn.AltinnCustomException = {
        objectId: "altinnCustomException",
        status: response.status,
        statusText: response.statusText || "",
        statusTextDetails: null,
        statusTextDetailsIsArray: false
    }
    const json = response.responseJSON ? response.responseJSON() : null
    if(json != null){
        const hasValidationErrors = json.hasOwnProperty("ValidationErrors")
        if(hasValidationErrors){
            errorObj.statusTextDetailsIsArray = Array.isArray(json.ValidationErrors)
            errorObj.statusTextDetails = json.ValidationErrors
        }else
            errorObj.statusTextDetails = JSON.stringify(json, null, 2)
    }
    return errorObj
}
export const InspectFetchApiError = async (response: Response) => {
    let errorObj : altinn.AltinnCustomException = {
        objectId: "altinnCustomException",
        status: response.status,
        statusText: response.statusText || "",
        statusTextDetails: null,
        statusTextDetailsIsArray: false
    }
    const text = await response.text()
    let json = null
    try{
        json = JSON.parse(text)

    }catch{}
    if(json != null){
        const hasValidationErrors = json.hasOwnProperty("ValidationErrors")
        if(hasValidationErrors){
            errorObj.statusTextDetailsIsArray = Array.isArray(json.ValidationErrors)
            errorObj.statusTextDetails = json.ValidationErrors
        }else
            errorObj.statusTextDetails = JSON.stringify(json, null, 2)
    }
    throw errorObj
}