import React, { FC, useContext, useEffect, useState } from 'react';
import { altinnAuth, altinnUserManager, logoutAltinn, useAuthState } from '../Auth';
import Api from '../api/Api';
import { Altinn } from '../interfaces/SharedTypes';
import { AppAlertModel } from '../interfaces/models/alert/Alerts';
import AltinnStoreV2 from '../legacy/stores/altinn/altinnStoreV2';
import { makeId } from '../utils/Utils';
import { AltinnContextInterface } from './AltinnContextInterface';
import { AppContext } from './AppContextProvider';
import { useQueryAltinnRole } from '../api/query/useQueryAltinnRole';
import { AltinnEnv } from '../utils/Constants';

// let timeout: any
export const AltinnContext = React.createContext<AltinnContextInterface | undefined>(undefined);

export const AltinnContextProvider: FC<AltinnContextInterface> = ({ children }) => {
    const appContext = useContext(AppContext)
    const ainAuthState = useAuthState()
    const { useAuthContext, useAuthState: useAltinnAuthState } = altinnAuth
    const { isLoading: altinnIsLoading, isAuthenticated: altinnIsAuthenticated, user } = useAltinnAuthState()
    const {
        altinnManageRoles, 
        altinnPlanByggesakRole, 
        altinnProtectedRoles, 
        altinnSufficientRoles
    } = AltinnEnv
    const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined)
    const [loggedInNoRights, setLoggedInNoRights] = useState<boolean | undefined>(undefined)
    const [reporteesRaw, setReporteesRaw] = useState<Altinn.Get.ReporteeResult>(null)
    const [rolesRaw, setRolesRaw] = useState<Altinn.Get.RoleResult>(null)
    const [chosenReportee, setChosenReportee] = useState("")
    const {
        data: acceptedRole,
        isLoading: acceptedRoleIsLoading,
        isError: acceptedRoleIsError,
        isFetched: acceptedRoleIsFetched
    } = useQueryAltinnRole(chosenReportee)
    const reportees: Altinn.Get.Reportee[] = reporteesRaw?._embedded.reportees
    const roles: Altinn.Get.Role[] = rolesRaw?._embedded.roles
    
    const acceptedRoles: Altinn.Get.Role[] = rolesRaw?._embedded?.roles?.filter(r => altinnSufficientRoles?.includes(r.RoleDefinitionId))
    // const keepAlive = async () => {
    //     if (loggedIn) {
    //         console.log("keeping alive...")
    //         try {
    //             await Api.instance()?.altinn.AltinnGetMessages("my")
    //             // handleLoggedIn(true)
    //         } catch (err) {
    //             handleLoggedIn(false)
    //         }
    //     }

    // }
    // useInterval(keepAlive, 5 * 60 * 1000)
    useEffect(() => {
        const runAsync = async () => {
            try {
                await getReportees()
            } catch (err: any) {
                if (err?.status == 401) {
                    handleLoggedIn(false, false)
                } else
                    handleLoggedIn(false)
            }
        }
        if (appContext?.appStore?.env?.altinnApiKey && Api.instance() != undefined && altinnIsAuthenticated)
            runAsync()
    }, [appContext?.appStore?.env?.altinnApiKey, Api.instance(), altinnIsAuthenticated])
    useEffect(() => {
        if (reporteesRaw) {
            handleLoggedIn(true)
        }
    }, [reporteesRaw])
    useEffect(() => {
        if(!user){
            setLoggedIn(false)
            setLoggedInNoRights(false)
            setReporteesRaw(null)
            setRolesRaw(null)
            setChosenReportee("")
        }
    }, [user])
    useEffect(() => {
        if (reporteesRaw?._embedded?.reportees?.length > 0 && appContext?.getSelectedFirm?.CustomerFirmId) {
            const runAsync = async () => {
                const insepctedReportee = inspectReportees(reporteesRaw)
                // if (insepctedReportee === "my")
                //     handleLoggedIn(true, true)
                // else
                //     handleLoggedIn(true)
                setChosenReportee(insepctedReportee)
                console.log("Chosen OrgNr", insepctedReportee);
                AltinnStoreV2.reportee = insepctedReportee
                AltinnStoreV2.reportees = reporteesRaw._embedded.reportees
            }
            runAsync()
        }

    }, [reporteesRaw, appContext?.getSelectedFirm?.CustomerFirmId])

    useEffect(() => {
        // console.log(acceptedRole, acceptedRoleIsError, acceptedRoleIsLoading, chosenReportee)
        if (!acceptedRoleIsFetched && chosenReportee === "my") {
            handleLoggedIn(true, true)
        }
        else if (acceptedRole && chosenReportee === appContext?.getSelectedFirm?.CustomerFirmId) {
            const acceptedRoles = acceptedRole?._embedded?.roles?.filter(r => altinnSufficientRoles.includes(r.RoleDefinitionId))
            if (acceptedRoles?.length > 0) {
                handleLoggedIn(true)
                setRolesRaw(acceptedRole)
                AltinnStoreV2.roles = acceptedRole
                console.log("Can represent", reportees)
            } else {
                handleLoggedIn(true, true)
            }
        } else if (!acceptedRoleIsLoading && !acceptedRoleIsError) {
            handleLoggedIn(true, true)
        }

    }, [chosenReportee, acceptedRoleIsFetched, acceptedRole, acceptedRoleIsError, acceptedRoleIsLoading])
    const handleLoggedIn = (loggedIn: boolean, noRights: boolean = false) => {
        if (!ainAuthState.user) return
        setLoggedIn(loggedIn)
        AltinnStoreV2.loggedIn = loggedIn
        setLoggedInNoRights(noRights)
        let text = ""
        let type = ""
        if (!loggedIn) {
            text = "Ikke innlogget i Altinn"
            type = "danger"
        } else if (loggedIn && noRights) {
            text = "Innlogget i Altinn, men ikke rettigheter til å representere selskapet"
            type = "danger"
        } else if (loggedIn && !noRights) {
            text = "Innlogget i Altinn"
            type = "success"
        }
        // appContext.addAlert({
        //     id: makeId(15),
        //     text: text,
        //     type: type,
        //     autodismiss: true,
        //     persistent: true
        // } as AppAlertModel)
    }
    const getReportees = async () => {
        try {
            const result = await Api.instance().altinn.AltinnGetReportees();
            if (result != null) {
                result?._embedded?.reportees?.forEach(p => {
                    if (p.SocialSecurityNumber != undefined)
                        p.SocialSecurityNumber = "*"
                })
                setReporteesRaw(result)
            }
        } catch (error) {
            appContext.addAlertAsException(error)
        }

    }
    const inspectReportees = (reportees: Altinn.Get.ReporteeResult): string => {
        try {
            const orgnr = appContext?.getSelectedFirm.CustomerFirmId;
            return Api.instance().altinn.AltinnInspectReportees(reportees, orgnr)
        } catch (err) {
            appContext.addAlertAsException(err)
            console.log("::: Reportee info :::")
            console.log("Can represent", reportees)
            console.log("Chosen OrgNr", chosenReportee);
            return "my";
        }
    }
    const login = async () => {
        // Api?.instance()?.altinn.LogInIdPort()
        altinnUserManager.signinRedirect({ prompt: "login", state: document.location.pathname + window.location.search })
            .catch((err) => {
                console.log("Login failed", err)
            })
    }
    return (
        <AltinnContext.Provider value={{
            state: {
                loggedIn: loggedIn,
                loggedInNoRights: loggedInNoRights,
                reporteesRaw: reporteesRaw,
                reportees: reportees,
                rolesRaw: rolesRaw,
                roles: roles,
                acceptedRoles: acceptedRoles,
                chosenReportee: chosenReportee,
                reporteeSet: chosenReportee?.length > 0 && chosenReportee != "my"
            },
            rolesLookup: {
                sufficientRoleList: altinnSufficientRoles,
                planByggesakRole: altinnPlanByggesakRole,
                protectedAltinnRoles: altinnProtectedRoles,
                manageAltinnRoles: altinnManageRoles
            },
            firm: appContext?.getSelectedFirm,
            login: login,
            logout: async () => {
                await logoutAltinn()
                AltinnStoreV2.resetLoggedInState()
                handleLoggedIn(false, false)

            }
        }}>
            {children}
        </AltinnContext.Provider>
    )
}