import { createContext, FC, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import { HttpResponse } from "../api/AbApi";
import Api from "../api/Api";
import { UploadFile } from "../api/Model/Files";
import { LogItemCreateDto, LogItemFile } from "../interfaces/models/hub/Logs";
import { AppContext } from "./AppContextProvider";
import { IntraContextInterface } from "./IntraContextInterface";

export const IntraContext = createContext<IntraContextInterface|undefined>(undefined)

export const IntraContextProvider: FC<IntraContextInterface&PropsWithChildren> = (props) => {
    const appContext = useContext(AppContext)
    const { prosjektId, nid, nabovarselId } = useParams()
    const LogToIntra = async (data: LogItemCreateDto) : Promise<HttpResponse<any>> => {
        try {
            return await Api.instance().ab.post(`${appContext?.appStore?.env.logServer}/api/log`, data)
        } catch (error) {
            appContext.addAlertAsException(error)
        }
    }
    
    const ToLogModel = (title: string, error: boolean, text: string, files: UploadFile | UploadFile[], tags: string): LogItemCreateDto => {
        
        const { getUser: user, getSelectedFirm: firm } = appContext
        if(nid || nabovarselId) title += `. NodeId: ${nid || nabovarselId}` 
        return {
            title: title,
            text: text,
            createCase: error ? true : false,
            createdByUser: user.email,
            emailFrom: user.email,
            projectId: prosjektId,
            firmId: firm.CustomerFirmId,
            status: !error ? { title: "ferdig" } : { title: "opprettet" },
            files: Array.isArray(files) ? files.map(x => uploadFileToLogItemFile(x)) : [uploadFileToLogItemFile(files)],
            source: { title: "byggsok.net" },
            tags: "byggsok.net, innsending",
            category: "it"
        } as LogItemCreateDto
    }
    const uploadFileToLogItemFile = (file: UploadFile): LogItemFile => {
        return {
            title: "debug",
            contenType: "application/json",
            filename: "debug.json",
            url: file.uri
        }
    }

    return(
        <IntraContext.Provider value={{
            LogToIntra: LogToIntra,
            ToLogModel: ToLogModel
        }}>
            {props.children}
        </IntraContext.Provider>
    )
}