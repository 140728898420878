import { action, decorate, observable, toJS } from "mobx";
import { view, notifications } from "../interfaces/types";
import LogEmailService from "../utilities/LogEmailService";
import { simpleSpinner } from "../utilities/utilityFunc";
import AppStore from "./AppStore";


class InnsendingsStore {

    prosessList: Array<string|notifications.FileUpload>
    settings: view.InnsendingsInitProp
    innsendingsstatus: view.Innsendingsinformasjon
    messageIdDeleted: boolean
    metaData: any
    private instantiate = action(() => {
        simpleSpinner(null, true)
        this.messageIdDeleted = false
        if(!this.prosessList) this.prosessList = []
        if(!this.innsendingsstatus) this.innsendingsstatus = {}
        this.metaData = this.metaData || {}
    })

    public getMessageIdDeleted = () => {
        return this.messageIdDeleted
    }
    public setMessageIdDeleted = action(() => {
        this.messageIdDeleted = true
        this.innsendingsstatus.messageId = null;
    })

    public clear = () => {
        this.instantiate()
    }
    public get wholeProsess(): Array<string|notifications.FileUpload> {
        return this.prosessList
    }

    public get fileUploads(): Array<notifications.FileUpload>{
        return this.prosessList.filter(step => typeof step === "object") as Array<notifications.FileUpload>
    }

    public get status(): view.Innsendingsinformasjon{
        return this.innsendingsstatus
    }
    getLog = (): string => {
        const toLog = {
            prosess: Object.assign({}, this.wholeProsess),
            status: Object.assign({}, this.status),
            metaData:Object.assign({}, this.metaData)
        }
        return JSON.stringify(toLog)
    }
    public addStatus = action((key: string, value: any) => {
        this.innsendingsstatus[key] = value
        if(key == "status" && (value == "ok" || value == "error")){
            LogEmailService.LogInnsending(this.status.status, this.getLog())
        }
            
    })
    public addMetadata = action((key: string, value: any) => {
        this.metaData[key] = value
    })
    public prosess = action((step: string|notifications.FileUpload) => {
        this.instantiate()
        if(typeof step === "object"){
            if (!this.fileUploads.some(s => s.id == step.id)) {
                this.prosessList.push(step);
            }else{
                this.fileUploads.map((element: notifications.FileUpload, index: number) => {
                    if (step.id == element.id) {
                        element.percentComplete = step.percentComplete;
                        element.error = step.error
                    }
                })
            }
        }else
            this.prosessList.push(step)
    })

    public newProsess = (settings: view.InnsendingsInitProp) => {
        this.settings = settings;
        this.instantiate()
        window.scrollTo({top: 0, behavior: "smooth"})
    }



}


decorate(InnsendingsStore, {
    prosessList: observable,
    innsendingsstatus: observable,
    messageIdDeleted: observable,
    metaData: observable
})
const InnsendingsprosessStore = new InnsendingsStore();
export default InnsendingsprosessStore;