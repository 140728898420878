import * as model from './Model/Files'
import { AbHubAzure } from '../interfaces/AbHubTypes';
import AbApi from './AbApi';
import { BlobConfigModel, BlobConfigParameters, BlobConfigSendSoknad, FileConfig } from './blobConfig/BlobConfig';
import { handleFetchError, handleFetchErrorV2 } from './HandleFetchError';
import { SoknadStatusSuppleringAtilDto } from '../components/soknadListWtihStatuses/models/SoknadStatusModels';
import toast from 'react-hot-toast';


class HubFileApi {
    private api: AbApi
    constructor(api: AbApi) {
        this.api = api
    }

    stripTrailingSlash = (str) => {
        return str.endsWith('/') ?
            str.slice(0, -1) :
            str;
    };

    stripStartSlash = (str) => {
        return str.startsWith('/') ?
            str.slice(0, 1) :
            str;
    };


    ValidAreaPath = (str) => {
        switch (str) {
            case 'sok/arbeidstilsynet':
                return true;
                break;
            default:
                return false;
        }
    }
    /**
     * 
     * @param Server Filserver - https://dev-files-ab.azurewebsites.net
     * @param File File som javascript File
     * @param FirmId  Oragnisasjonsnummer
     * @param ProjectId Prosjektid, QPid
     * @param AreaPath Bane til denne type fil f.eks f. eks sok/arbeidstilsynet, sok/ettrinn,sok/ramme,sok/gjennomforingsplan,sok/ansvarsrett,sok/samsvar,sok/endring,sok/ferdigattest,sok/ig
     * @param Version Versjon, starter med 1
     * @param SubPath f. eks vedlegg
     * @param AttachmentType Type vedlegg, f.eks annet
     * @param AttachmentGroup Vedleggsgruppe f. eks G
     */
    async UploadFile(pathProvider: BlobConfigModel, projectPath: string, File: File, AttachmentType: string, AttachmentGroup: string = "", AttachmentProperties: string = "", additionalMetas: model.Meta[] = []) {
        const data = new FormData();
        data.append('upload', File);
        var setup = {
            projectid: pathProvider.parameters.projectId,
            projectIdGuid: pathProvider.parameters.projectIdGuid,
            projectpath: projectPath,
            type: AttachmentType,
            AttchmentGroup: AttachmentGroup,
            AttachmentProperties: AttachmentProperties,
            size: File.size
        };
        if(additionalMetas.length > 0){
            additionalMetas.forEach((meta) => {
                if(meta.key && meta.value)
                    setup[meta.key] = meta.value;
            })
        }
        data.append("filemeta", JSON.stringify(setup));
        let fileres = await this.api.uploadfile<model.UploadFiles>(pathProvider.configuredUrls.postAttachmentUrl(), data)
        if(fileres?.ok){
            return fileres;
        }
        toast.error(await handleFetchErrorV2(fileres))
        return null   
    }
    /**
     * 
     * @param pathProvider 
     * @param Url 
     * @returns 
     */
    EditAttachmentMetadata = async (pathProvider: BlobConfigModel, update: AbHubAzure.AttachmentUpdateMeta) => {
        return await this.api.put<AbHubAzure.Attachment>(`${pathProvider.configuredUrls.editAttachmentMetadata()}?FirmId=${pathProvider.parameters.firmId}`, update)
    }
    EditAttachmentMetadataList = async (pathProvider: BlobConfigModel, update: AbHubAzure.AttachmentUpdateMetaList) => {
        const response = await this.api.put<model.Meta[]>(`${pathProvider.configuredUrls.editAttachmentMetadataList()}?FirmId=${pathProvider.parameters.firmId}`, update)
        if(response?.ok){
            return response;
        }
        toast.error(await handleFetchErrorV2(response))
        return null   
    }
    /**
     * 
     * @param Server 
     * @param Url 
     */
    DeleteFile = async (pathProvider: BlobConfigModel, Url: string) => {
        const response = await this.api.deleteReq<model.UploadFiles>(pathProvider.configuredUrls.deleteAttachmentUrl() + "?url=" + Url)
        if(response?.ok){
            return response;
        }
        toast.error(await handleFetchErrorV2(response))
        return null   
    }

    async GetDownloadUrl(Server: string, Url: string) {
        let url = await this.api.get<model.SignedUrl>(Server + "/api/File/sign?url=" + Url)
        if(url.ok){
            return url
        }
        toast.error(await handleFetchErrorV2(url))
        return null
    }
    // fetchAsBlob = async (Server: string, url, name: string) => {
    //     var surl = Server + "/api/File/downloadbytearray?url=" + url;
    //     var res = await fetch(surl, { method: 'get', headers: await this.api.authheaders(surl, false, null) });
    //     let restext: string = await res.text();
    //     restext = restext.replace(/\"/g, "");
    //     return atob(restext);
    // }
    fetchAsBlobv2 = async (Server: string, url, name: string) => {
        var surl = Server + "/api/File/download?url=" + url;
        var res = await fetch(surl, { method: 'get', headers: await this.api.authheaders(surl, false, null) });
        if(res?.ok){
            let restext = await res.blob();
            return restext;
        }
        toast.error(await handleFetchErrorV2(res))
        return null
    }


    /**
     * 
     * @param Server 
     * @param FirmId 
     * @param ProjectId 
     * @param AreaPath f. eks sok/arbeidstilsynet eller sok/ettrinn,sok/ramme,sok/gjennomforingsplan, sok/ansvarsrett, sok/samsvar, sok/endring, sok/ferdigattest, sok/ig
     * @param Version 
     * @param SubPath 
     */
    async GetFileList(pathProvider: BlobConfigModel) {
        return await this.GetFileListPath(pathProvider);
    }
    async GetFileListPath(pathProvider: BlobConfigModel) {
        const resfiles = await this.api.get<model.HubFiles[]>(pathProvider.configuredUrls.filesLocation());
        if(resfiles?.ok){
            var m = { files: resfiles.parsedBody } as model.FileResponse;
            return m;
        }
        toast.error(await handleFetchErrorV2(resfiles))
        return null
    }
    async GetSoknadVersion(Server: string, FirmId: string, ProjectId: string, Path: string, flatList: boolean = false) {
        const resfiles = await this.api.get<model.SoknadVersionDto>(Server + "/api/Container/GetSoknadVersions?firmId=" + FirmId + "&prefix=" + ProjectId + "/" + Path+"&flatList="+flatList);
        if(resfiles?.ok){
            return resfiles.parsedBody;
        }
        toast.error(await handleFetchErrorV2(resfiles))
        return null
    }
    async GetSoknadStatus(Server: string, FirmId: string, soknadType: string, ProjectId: string, Path: string) {
        const resfiles = await this.api.get<SoknadStatusSuppleringAtilDto[]>(Server + "/api/Container/GetSoknadStatusesInProject?firmId=" + FirmId + "&soknadType="+soknadType + "&prefix=" + ProjectId + "/" + Path);
        if(resfiles?.ok){
            return resfiles.parsedBody;
        }
        toast.error(await handleFetchErrorV2(resfiles))
        return null
    }
    async GetFilesInFolder(Server: string, FirmId: string, ProjectId: string, Path: string) {
        const resfiles = await this.api.get<model.FileVersionDto[]>(Server + "/api/Container?firmId=" + FirmId + "&prefix=" + ProjectId + "/" + Path);
        if(resfiles?.ok){
            return resfiles.parsedBody;
        }
        toast.error(await handleFetchErrorV2(resfiles))
        return null
    }
    base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);
        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);
            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }
    /**
     * 
     * @param Server Server navn
     * @param url url til fil
     * @param filename filnavn
     */

    DownloadFileWithFileServer = async (fileServer: string, url: string, filename: string) => {
        var blobstring = await this.fetchAsBlobv2(fileServer, url, filename);
        var url = window.URL.createObjectURL(blobstring);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();

    }
    DownloadFile = async (pathProvider: BlobConfigModel, url: string, filename: string) => {
        this.DownloadFileWithFileServer(pathProvider.file.server, url, filename)
    }
    GetFile = async (pathProvider: BlobConfigModel, url: string, filename: string) => {
        var blob = await this.fetchAsBlobv2(pathProvider.file.server, url, filename);
        return new File([blob], filename, { type: blob.type })
    }


    // async sendJsonToStorage(pathProvider: BlobConfigModel|BlobConfigSendSoknad, data: string, SubPath: string = "", filename: string, contentType: string = "application/json") {
    //     const response = await this.api.post<model.UploadFile>(pathProvider.file.server + "/api/UploadText/" + pathProvider.parameters.firmId,
    //         {
    //             "fileName": filename,
    //             "contentType": contentType,
    //             "content": data,
    //             "projectId": pathProvider.parameters.projectId,
    //             "projectPath": this.stripTrailingSlash(pathProvider.file.rootPath) + "/v" + pathProvider.file.version + "/" + this.stripStartSlash(this.stripTrailingSlash(SubPath))
    //         }
    //     );
    //     return response.parsedBody;
    // }
    /**
     * 
     * @param Server 
     * @param data 
     * @param FirmId 
     * @param ProjectId 
     * @param AreaPath f. eks sok/arbeidstilsynet eller sok/ettrinn,sok/ramme,sok/gjennomforingsplan, sok/ansvarsrett, sok/samsvar, sok/endring, sok/ferdigattest, sok/ig
     * @param Version 
     * @param SubPath 
     * @param filename 
     */
    async logDataToStorage(fileConfig: FileConfig, params: BlobConfigParameters, data: string, SubPath: string = "", filename: string, contentType: string = "application/json") {
        const response = await this.api.post<model.UploadFile>(fileConfig.server + "/api/UploadText/" + params.firmId,
            {
                "fileName": filename,
                "contentType": contentType,
                "content": data,
                "projectId": params.projectId,
                "projectPath": this.stripTrailingSlash(fileConfig.rootPath) + "/v" + fileConfig.version + "/" + this.stripStartSlash(this.stripTrailingSlash(SubPath))
            }
        );
        if(response?.ok){
            return response.parsedBody;
        }
        toast.error(await handleFetchErrorV2(response))
        return null
    }
    
}
export default HubFileApi

