
import { useNotifications } from '../../../contexts/NotificationContextProvider';
import ErrorNotification from './ErrorNotification';
import styles from './styles.module.scss'

const NotificationContainer = () => {

    const {
        errorToast,
        toasts,
        removeToast
    } = useNotifications()
    // console.log("toasts", toasts)
    return (
        <div className={styles.container}>
            {toasts.map(t => {
                return <ErrorNotification key={t.id} toast={t} />
            })}
        </div>
    )
}

export default NotificationContainer;
