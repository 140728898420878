import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import Api from '../api/Api';
import { reactPlugin, setAiUser } from '../AppInsightsInit';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { Ab } from '../interfaces/abTypes';
import { server } from '../legacy/interfaces/types';
import AltinnStoreV2 from '../legacy/stores/altinn/altinnStoreV2';
import AppStore from '../legacy/stores/AppStore';
import { AppContextInterface } from './AppContextInterface';
import { FirmAccess } from "@arkitektbedriftene/fe-lib/oidc";
import { AlertModelType, AppAlertModel } from '../interfaces/models/alert/Alerts';
import toast, { useToasterStore } from 'react-hot-toast';
import useExceptionEvent from '../hooks/applicationInsights/useExceptionEvent';
// declare global {
//     interface Window {
//         _paq: any[]
//     }
// }

interface Props {

}


export const AppContext = React.createContext<AppContextInterface | undefined>(undefined);

export const AppContextProvider: FC<Props & AppContextInterface & React.PropsWithChildren> = (props) => {
    const [user, setUser] = useState<Ab.User>()
    const [selectedFirm, setSelectedFirm] = useState<FirmAccess>()
    const [appStoreLoaded, setAppStoreLoaded] = useState<boolean>(false)
    const [userCompanyLoaded, setUserCompanyLoaded] = useState<boolean>(false)
    const [fullscreen, setFullScreen] = useState<boolean>(false)
    const exceptionTrack = useExceptionEvent(reactPlugin)
    const { toasts, pausedAt } = useToasterStore();
    const api = useRef<Api>()
    const env = useRef<server.AppEnv>()

    const useTitle = useDocumentTitle()
    useEffect(() => {
        if (!env.current && !api.current)
            buildAppEnv()
        useTitle.setTitleBase()
    }, [])
    useEffect(() => {
        if (selectedFirm) {
            setUserCompanyLoaded(true)
            setAiUser(user?.username, selectedFirm.CustomerFirmId)
            new Cookies().remove("callTries")
        }
        return () => {
            setUserCompanyLoaded(false)

        }
    }, [selectedFirm])

    const buildAppEnv = () => {
        const _envVariables = {
            maksSokApiServer: process.env.REACT_APP_MAKS_SOK_API_SERVER,
            instrumentationkey: process.env.REACT_APP_INSTRUMENTATION_KEY,
            prodEnv: JSON.parse(process.env.REACT_APP_PROD_ENV),
            altinnPath: process.env.REACT_APP_ALTINN_PATH,
            altinn3Server: process.env.REACT_APP_ALTINN_3_SERVER,
            altinnApiKey: process.env.REACT_APP_ALTINN_API_KEY,
            ftbApiUrl: process.env.REACT_APP_FTB_API_URL,
            byggsokServer: process.env.REACT_APP_BYGGSOK_SERVER,
            securityServer: process.env.REACT_APP_SECURITY_SERVER,
            fileServer: process.env.REACT_APP_FILE_SERVER,
            commonServer: process.env.REACT_APP_COMMON_SERVER,
            customerServer: process.env.REACT_APP_CUSTOMER_SERVER,
            ConvertServer: process.env.REACT_APP_CONVERT_SERVER,
            arbeidstilsynetApi: process.env.REACT_APP_ARBEIDSTILSYNET_API,
            arbeidstilsynetGebyrKalkulator: process.env.REACT_APP_ARBEIDSTILSYNET_GEBYR_KALKULATOR,
            logServer: process.env.REACT_APP_LOG_SERVER,
            ambitaBliKundeServer: process.env.REACT_APP_AMBITA_BLI_KUNDE,
            ambitaNabovarselServer: process.env.REACT_APP_AMBITA_NABOVARSEL,
            dibkConvertApi: process.env.REACT_APP_DIBK_CONVERT_API,
            dibkImportWizard: process.env.REACT_APP_DIBK_IMPORT_WIZARD
        } as server.AppEnv
        const _api = Api.instantiate({
            apiBaseUrl: _envVariables.altinnPath,
            headers: {
                Accept: "application/hal+json",
                ApiKey: _envVariables.altinnApiKey
            }
        })
        AltinnStoreV2.setAltinnParameters(_envVariables.altinnPath, _envVariables.altinnApiKey)
        api.current = _api
        env.current = _envVariables
        AppStore.envVariables = _envVariables
        setAppStoreLoaded(true)
    }
    return (
        <AppContext.Provider value={{
            fullScreen: fullscreen,
            setFullScreen: setFullScreen,
            setUser: (user: Ab.User) => {
                if (user) {
                    // console.log("AppCtxUser", user)
                    setUser(user)
                    AppStore.setUser(user)

                }
            },
            getUser: user,
            getSelectedFirm: selectedFirm,
            appStore: AppStore,
            appStoreLoaded: appStoreLoaded,
            userCompanyLoaded: userCompanyLoaded,
            api: api.current,
            setSelectedFirm: (firm: FirmAccess) => {
                // console.log("SETTING USER", firmId, user)
                if (firm) {
                    setSelectedFirm(firm)
                    new Cookies().set("currentCompany", firm, { path: "/" })
                }

            },
            useDocumentTitle: () => useTitle,
            addAlertAsException: (exception: unknown, isError = true) => {
                exceptionTrack(exception, { event: "alertException" })
                const asString = typeof exception === "string" ? exception : JSON.stringify(exception)
                if(asString != "" && asString != "null" && asString != "{}")
                    toast.error(asString)
            },
            // contextLevel: handleContextLevel,
            appStoreData: {
                envVariables: env.current
            }
        }}>
            <React.Fragment>
                {props.children}

            </React.Fragment>
        </AppContext.Provider>
    )
}

export const useAppContext = () => {
    const context = useContext(AppContext)
    if (!context) {
        throw new Error("useAppContext must be used within an AppContextProvider")
    }
    return context
}