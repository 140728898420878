import fetchIntercept, { FetchInterceptorResponse } from 'fetch-intercept';
import { makeId } from '../utils/Utils';
import { ByggsokEventType } from '../utils/ByggsokEventType';
import { difiTokenEndpoint, userManager } from '../Auth';
import { appInsights } from '../AppInsightsInit';
import { isAinUrl, isMaksSokBackend } from '../utils/isAiNUrl';
import toast from 'react-hot-toast';


export const registerIntercept = () => {

    const handleRequestSpinner = (url: string | Request, config) => {
        let toLog = ""
        if (typeof url === "string" && config?.method?.toLowerCase() === "post") {
            toLog = url
        } else if (url instanceof Request) {
            if (url.method === "POST") {
                toLog = url.url
            }
        }
        if (toLog != "") {
            var e = new CustomEvent("spinnerEvent", {
                detail: {
                    url: toLog,
                    add: true
                }
            });
            document.dispatchEvent(e);
        }
    }
    const handleResponseSpinner = (response: FetchInterceptorResponse) => {
        if (!response) return
        if (response?.request?.method === "POST") {
            var e = new CustomEvent("spinnerEvent", {
                detail: {
                    url: response.request.url,
                    add: false
                }
            });
            document.dispatchEvent(e);
        }
    }
    const resetSpinner = () => {
        var e = new CustomEvent("spinnerEvent", {
            detail: {
                reset: true
            }
        });
        document.dispatchEvent(e);
    }

    const unregister = fetchIntercept.register({
        request: function (url: string | Request, config) {
            // handleRequestSpinner(url, config)
            // console.log({url})
            // processes.push(url)
            // Modify the url or config here
            return [url, config];
        },

        requestError: function (error) {
            // Called when an error occured during another 'request' interceptor call
            //TODO:: Log
            // resetSpinner()
            return Promise.reject(error);
        },

        // response: function (response) {
        //     // Modify the reponse object
        //     //console.log(processes)
        //     handleResponseSpinner(response)
        //     if (!response) throw new Error("Noe gikk galt (Fetch). Vennligst kontakt Arkitektbedriftene.")
        //     if (response.ok) {
        //         return response;
        //     }
        //     let text = ""
        //     const asAsync = async () => {
        //         const req = response.request.url
        //         let responseContent = ""
        //         try {
        //             const txt = await response.text()
        //             responseContent = txt
        //             const parsed = JSON.parse(txt)
        //             if (parsed.hasOwnProperty("title")) responseContent = parsed["title"] //Feks matrikkelController returnerer NotFound med title i JSON
        //             if (parsed.hasOwnProperty("Message")) responseContent = parsed["Message"] //Typisk MaksSok WebApi return
        //         } catch { }
        //         if (responseContent === "") {
        //             try {
        //                 const body = await response.json()
        //                 if (body && body.hasOwnProperty("Message")) {
        //                     responseContent = body.Message
        //                 }
        //             } catch { }
        //         }
        //         if (responseContent === "" && response.statusText) {
        //             responseContent = response.statusText
        //         }

        //         if (responseContent === "") {
        //             switch (response.status) {
        //                 case 400:
        //                     responseContent = `400 - Ugyldig forespørsel`
        //                     break;
        //                 case 401:
        //                     responseContent = `401 - Ikke autentisert`
        //                     handle401(response)
        //                     break;
        //                 case 403:
        //                     responseContent = `403 - Ikke tilgang`
        //                     break;
        //                 case 404:
        //                     responseContent = `404 - Fant ikke`
        //                     break;
        //                 case 500:
        //                     responseContent = `500 - Ukjent feil`
        //                     break;
        //                 case 503:
        //                     responseContent = `503 - Server ikke tilgjengelig`
        //                     break;
        //                 default:
        //                     responseContent = `${response.status}`
        //             }
        //         }

        //         responseContent = `${responseContent} <br/>${req}`
        //         text = responseContent
        //         if (response.status === 401) handle401(response)
        //         else if (response.status !== 404) {
        //             resetSpinner()
        //             addAlertCallback({
        //                 id: makeId(15),
        //                 text: text,
        //                 persistent: true,
        //                 type: response.status === 404 ? "warning" : "danger",
        //                 isFetchCatch: true
        //             })
        //         }

        //         return response
        //         // debugger
        //         //TODO:: Log
        //         //TODO:: Vise ulik informasjon avhengig av statuscode?
        //         // throw Error(text)
        //     }
        //     asAsync()
        // },

        responseError: function (err) {
            // const displayList = [".azurewebsites.net", ".arkitektbedriftene.no", "localhost", ".abin.no"]
            // const isDifiTokenCall = err?.request?.url?.includes(difiTokenEndpoint)
            // const displayNetworkWait = displayList.some(x => err?.request?.url?.includes(x))
            // const isApplicationInsightsCall = err?.request?.url?.includes("applicationinsights.azure.com")
            // const text = `Et kall mot ${err?.request?.url} feilet med feilmelding ${err?.message}`
            // if (isApplicationInsightsCall) return
            // if (isDifiTokenCall && err?.message === "Failed to fetch") {
            //     // toast.error("Du er blitt utlogget av Altinn. Vennligst logg inn på nytt.", { id: "AltinnLogout" })
            // }
            // console.log("FetchIntercept", err)
            // if (err?.message === "Failed to fetch" && displayNetworkWait) {
            //     appInsights.trackException({
            //         exception: err, properties: {
            //             url: err?.request?.url,
            //             message: err?.message
            //         }
            //     })
            //     var e = new CustomEvent("byggsokEvent", {
            //         detail: ByggsokEventType.NETWORK_ERROR
            //     });
            //     document.dispatchEvent(e);
            // }
            // else {
            //     // toast.error(text, { id: text })
            //     return Promise.reject(text)
            // }
            return Promise.reject(err);
        }
    });
    return unregister
}

const handle401 = async (response: fetchIntercept.FetchInterceptorResponse) => {
    const reqUrl = response.request.url

    //Hvis MAKS-søk backend så må vi forsøke å få ny cookie
    if (isMaksSokBackend(reqUrl)) {
        var e = new CustomEvent("byggsokEvent", {
            detail: ByggsokEventType.REFRESH_COOKIE
        });
        document.dispatchEvent(e);
    }
    //His ikke AiN-Url, bare returner melding
    else if (!isAinUrl(reqUrl)) return response

    //Fjern bruker fra userManager. Det vil trigge en "isAuthorized = false" og en silentSignin
    else userManager.removeUser()

}
// Unregister your interceptor
// unregister();

export const SupressFetchError = (err: any, supressType: string, executeIfNotSupress: Function) => {
    if (typeof err === "object" && (err?.message?.startsWith(supressType) || err?.status === 404)) {
        return
    }
    executeIfNotSupress()
}

