import { useEffect, useRef, useState } from "react";


const useDocumentTitle = () => {
    const title = useRef<string>()
    const titleBase = useRef<string>()
    const setTitleBase = (base?: string) => {
        titleBase.current = base || "MAKS-søk "
        setTitle(title.current)
    }
    const setTitle = (newTitle: string) => {
        title.current = newTitle
        let _newTitle = titleBase.current != undefined ? titleBase.current : ""
        if (title.current != undefined)
            _newTitle = `${_newTitle} | ${title.current}`
        window.document.title = _newTitle
    }
    return { setTitle, setTitleBase };
};
export default useDocumentTitle;