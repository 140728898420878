import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { reactPlugin } from './AppInsightsInit';
import Auth from './Auth';
import './api/FetchIntercept';
import { AppContextProvider } from './contexts/AppContextProvider';
import { IntraContextProvider } from './contexts/IntraContextProvider';
import { SystemEnvContextProvider } from './contexts/EnvContextProvider';
import ToasterConfig from './ToasterConfig';
import { parseError } from './api/HandleFetchError';
import toast from 'react-hot-toast';
import { NotificationContextProvider } from './contexts/NotificationContextProvider';
import { registerIntercept } from './api/FetchIntercept';
import DateUtils from './utils/DateUtils';
import InactivityDialog from './InactivityDialog';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 15, // 15 minutes
      // onError: async (err) => {
      //   const str = await parseError(err)
      //   toast.error(str, {id: str})
      // }
      refetchOnWindowFocus: (query) => {
        // console.log("refetchOnWindowFocus", query)
        // console.log("refetchOnWindowFocus", query.state.dataUpdateCount, query.state.dataUpdatedAt)
        if(query.state.dataUpdateCount == 0) return false
        const now = DateUtils.now()
        const lastFetch = new Date(query.state.dataUpdatedAt)
        // console.log(now, lastFetch)
        const diff = now.getTime() - lastFetch.getTime()
        // console.log("diff", diff, DateUtils.toShortStr(now), DateUtils.toShortStr(lastFetch))
        const shouldRefetch = diff > (1000 * 60 * 60)
        if (shouldRefetch) {
          const e = new CustomEvent("inactivityEvent", {detail: diff});
          document.dispatchEvent(e);
        }
        return false
      }

    }
  }
})
registerIntercept()

const App = () => {

  return (
    <SystemEnvContextProvider>
      <NotificationContextProvider>
        <ToasterConfig />
        <InactivityDialog />
        <AppInsightsContext.Provider value={reactPlugin}>
          <QueryClientProvider client={queryClient}>

            <AppContextProvider>
              <IntraContextProvider>
                <Auth />
              </IntraContextProvider>
            </AppContextProvider>

            <ReactQueryDevtools />
          </QueryClientProvider>
        </AppInsightsContext.Provider>
      </NotificationContextProvider >
    </SystemEnvContextProvider>

  )
}

export default App;
