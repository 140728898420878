import { notifications } from '../interfaces/types';
import { getUrlParameter } from './utilityFunc';
import $ from 'jquery'
import { IsHtml } from '../../utils/TextUtils';
import { ByggsokEventType } from '../../utils/ByggsokEventType';


const CreateEventAndDispatch = (msg: string): void => {
    resetSpinner()
    if (msg == "" || msg == "{}") return
    let text = ""
    let parsed: any = null
    try {
        parsed = JSON.parse(msg)
    } catch { }
    if (parsed) {
        if (parsed.hasOwnProperty("responseText")) text = parsed["responseText"]
    } else {
        text = msg
    }
    dispatchCustomEvent(text)
    
}

const dispatchCustomEvent = (text:string|ByggsokEventType) => {
    var e = new CustomEvent("byggsokEvent", {
        detail: text
    });
    document.dispatchEvent(e);
}
export const AjaxInterceptors = () => {
    $(document).ajaxError((event, jqxhr, settings, thrownError) => {
        console.log({event})
        console.log({jqxhr})
        console.log({thrownError}) 
        // if(jqxhr.statusText === "error"){
        //     dispatchCustomEvent(ByggsokEventType.LOGG_INN_PAA_NYTT)
        // }
        // else 
        if(jqxhr?.responseText === "Forsøkte å utføre en uautorisert operasjon." 
            || jqxhr?.responseText === "Attempted to perform an unauthorized operation." 
            || jqxhr?.responseText === "Authorization has been denied for this request."){   
            dispatchCustomEvent(ByggsokEventType.REFRESH_COOKIE)
        }
        else CreateEventAndDispatch(jqxhr?.responseText);

    });
    $(document).ajaxSend((handler, jqxhr, ajaxOptions) => {
        handleRequestSpinner(ajaxOptions)
    })
    $(document).ajaxSuccess((event, request, ajaxOptions) => {
        handleResponseSpinner(ajaxOptions)

        // if (request.responseJSON != undefined && request.responseJSON.Type != undefined && request.responseJSON.Title != undefined) {
        //     //console.log(request.responseJSON)
        //     let msg: notifications.ApiMsg = {
        //         type: "OK",
        //         title: request.responseJSON.Title as string
        //     }
        //     CreateEventAndDispatch(msg);
        // }
    });

}

export let ApiSuccess = (message: string) => {
    let msg: notifications.ApiMsg = {
        type: "OK",
        title: message
    }
    // CreateEventAndDispatch(msg);
}

export let ApiError = (message: string) => {
    CreateEventAndDispatch(message);
    //displayError(msg || {Title:"Noe gikk galt", Message:"Ta kontakt dersom problemet vedvarer"});
}

export let ApiErrorServerLog = (message: notifications.ServerLog) => {
    // CreateEventAndDispatch(message);
}


const handleRequestSpinner = (ajaxOptions: JQuery.AjaxSettings) => {
    const { type, url } = ajaxOptions
    // console.log("Req", {type}, {url})
    if (!ajaxOptions) return
    if (type.toLowerCase() === "post"){
        var e = new CustomEvent("spinnerEvent", {
            detail: {
                url: url,
                add: true
            }
        });
        document.dispatchEvent(e);
    }
        
    
}
const handleResponseSpinner = (ajaxOptions: JQuery.AjaxSettings) => {
    const { type, url } = ajaxOptions
    // console.log("Res", {type}, {url})
    if (!ajaxOptions) return
    if (type.toLowerCase() === "post") {
        var e = new CustomEvent("spinnerEvent", {
            detail: {
                url: url,
                add: false
            }
        });
        document.dispatchEvent(e);
    }
}

const resetSpinner = () => {
    var e = new CustomEvent("spinnerEvent", {
        detail: {
            reset: true
        }
    });
    document.dispatchEvent(e);
}