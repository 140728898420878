import { api, htmlreact } from "../interfaces/types";
import Api from "../stores/Api";
import AppStore from "../stores/AppStore";


const sender = "no-reply@arkitektbedriftene.no";


class LogEmailService {
    
    LogInnsending = async (status: string, logObj: string) => {
        // Api.action("log").params({action: "LogInnsending", status: status}).method("POST")
        // .data({data:logObj})
        // .success((response: any) => {
        //     // console.log("LogChange success", response);
        // })
        // .error((err: Error) => {
            
        // })
    }
  
    LogChange = async (changeObject: htmlreact.ElementChangedLog) => {
        // let toLog = Object.assign({}, changeObject)
        // toLog.event = null;
        // try{
        //     if(changeObject != undefined){
        //         Api.action("log").params({action: "LogChange"}).method("POST")
        //         .data({data:toLog})
        //         .success((response: any) => {
        //             // console.log("LogChange success", response);
        //         })
        //         .error((err: Error) => {
        //             console.log("LogChange error", toLog, err)
        //         })
        //     }
        // }catch(err){
        //     console.log(err)
        // }
        
        
    }

    LogEvent = async (eventName: string, start?: boolean) => {
        // this.LogChange({
        //     type: "event",
        //     what: eventName,
        //     newValue: start == true ? `Startet ${start}` : "",
        //     target: ""
        // })
    }
}

export default new LogEmailService();