import { observable, action, toJS } from 'mobx';
import { server, view } from '../interfaces/types';
import DateUtils from '../utilities/DateUtils';
import $ from 'jquery'
export class Context {

    constructor() {
        // this._sok.readServiceCodesFromHiddenFields();
    }

    /** PUBLIC methods */
    /**************************************************************************************/
    /**************************************************************************************/
    /**************************************************************************************/

    // get sok(): view.CtxSok { return this._sok; }
    get session(): view.CtxSession { return this._session }
    get local(): view.CtxSession { return this._local }
    get mainStore(): any { return this._mainStore; }
    set mainStore(store: any) { this._mainStore = store }
    get aiInstrumentationKey(): string { return this._appInsightsKey }
    set aiInstrumentationKey(key: string) { this._appInsightsKey = key }
    get constants() {
        return ({
            hasAttachments: _hasAttachments
        })

    }
    /** End PUBLIC methods */
    /**************************************************************************************/
    /**************************************************************************************/
    /**************************************************************************************/


    /** Data variables */
    private _mainStore: any = null;
    

    /** Session storage Api */
    private _session: view.CtxSession = {
        set: (key: string, data: any): void => {
            sessionStorage.setItem(key, data)
        },

        get: (key: string): any => {
            return sessionStorage.getItem(key)
        },

        remove: (key: string): void => {
            sessionStorage.removeItem(key)
        },

        clear: (): void => {
            sessionStorage.clear();
        },
        emptyByWildcardKey: (wildcard: string): void => {
            var n = sessionStorage.length;
            while (n--) {
                var key = sessionStorage.key(n);
                if (key.includes(wildcard)) {
                    sessionStorage.removeItem(key);
                }
            }
        }
    }
    /** End Session storage Api */


    /** localStorage Api */
    private _local: view.CtxSession = {
        set: (key: string, data: any): void => {
            localStorage.setItem(key, data)
        },

        get: (key: string): any => {
            return localStorage.getItem(key)
        },

        remove: (key: string): void => {
            localStorage.removeItem(key)
        },

        clear: (): void => {
            localStorage.clear();
        },
        emptyByWildcardKey: (wildcard: string): void => {
            var n = localStorage.length;
            while (n--) {
                var key = localStorage.key(n);
                if (key.includes(wildcard)) {
                    localStorage.removeItem(key);
                }
            }
        }
    }
    /** End localStorage Api */

    private _appInsightsKey: string


}

/** Soknad types that supports attachments */
enum _hasAttachments {
    "Rammesoknad" = "Rammesoknad",
    "Ettrinn" = "Ettrinn",
    "IG" = "IG",
    "MidlertidigBrukstillatelse" = "MidlertidigBrukstillatelse",
    "Ferdigattest" = "Ferdigattest",
    "Gjennomforingsplan" = "Gjennomforingsplan",
    "Endringssoknad" = "Endringssoknad"
}

