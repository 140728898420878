import { useContext } from "react"
import { useQuery } from "react-query"
import { AltinnContext } from "../../contexts/AltinnContextProvider"
import { AppContext } from "../../contexts/AppContextProvider"
import Api from "../Api"
import { createAltinnRoleKey } from "./queryTypes"
import { parseErrorAndToast } from "../HandleFetchError"


export const useQueryAltinnRole = (reportee: string) => {
    const appContext = useContext(AppContext)
    const altinnContext = useContext(AltinnContext)
    return useQuery({
        cacheTime: 0,
        queryKey: createAltinnRoleKey(reportee, appContext.getSelectedFirm?.CustomerFirmId),
        enabled: reportee != undefined && reportee.length > 0 && reportee != "my", 
        queryFn: async () => {
            return await Api.instance().altinn.AltinnGetRoles(reportee)
            .then(res => res)
            .catch(err => {
                appContext.addAlertAsException(err, true)
                return null
            })
        },onError: (err) => parseErrorAndToast(err)
    })
}

