import $ from 'jquery'
const _getUrlParameter = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)', 'i').exec(window.location.href);
    if (results == null) {
        const path = window.location.pathname
        if(!path || path.length <= 1) return null
        if(name.toLowerCase() == "qpid"){
            let parts = path.split("/")
            if(parts[0] == "") parts.splice(0, 1)
            if(parts[0] == "prosjekt" && parts.length > 1)
                return parts[1]
            return 0
        }
    }
    else {
        return results[1] || 0;
    }
}

const _setUrlParameter = function (key, value) {
    let params = new URLSearchParams(window.location.search);
    params.set(key, value);
    window.location.search = params;
}
const _setUrlParameters = function (objOfParams) {
    let searchParams = new URLSearchParams(window.location.search);
    let newParams = ""
    for(const [key, value] of Object.entries(objOfParams)){
        searchParams.set(key, value)
    }
    let href = window.location.pathname;
    href+=`?`+searchParams.toString()
    window.history.pushState({path: href}, "", href)
}
const _splitUrlParams = () => {
        let paramStr = window.location.search.slice(1);
        paramStr = paramStr.split("#")[0]
        const paramArr = paramStr.split("&");
        let parts = [];
        if (paramArr != undefined) {
            paramArr.forEach((part) => parts.push(part))
        }
        if (parts != undefined && parts.length > 0 && parts[0] != "") return parts;
        return undefined;
}
const _removeParamsFromUrl = (list) => {
    if(list && Array.isArray(list) && list.length){
        let params = _splitUrlParams()
        if(params && Array.isArray(params)){
            list.map(key => {
                const match = params.findIndex(x => x.includes(key))
                if(match != -1) 
                    params.splice(match, 1)
            })
            _buildUrlParams(params)
        }

    }
}
const _buildUrlParams = (parts) => {
    if (parts && Array.isArray(parts)) {
        const path = window.location.pathname;
        let paramString = parts.length > 0 ? "?" : "";
        parts.map((p, index) => {
            paramString += p;
            if (index != parts.length - 1) paramString += "&"
        })
        window.history.pushState({path: path+paramString}, "", path+paramString)
    }
}


const _simpleSpinner = (target, show) => {
    let parent = $(target).parent();
    if (parent.length > 0) {
        if (show) {
            parent.append("<div class='simpleSpinner'><div class='simpleSpinner--loader'></div></div>")
        } else {
            parent.find(".simpleSpinner").remove();
        }
    } else if (parent.length == 0 && show == true) {
        $("body").prepend("<div class='simpleSpinner simpleSpinner-absolute'><div class='simpleSpinner--loader'></div></div>");
    } else {
        $(".simpleSpinner").remove();
    }

}

const _updateComplete = (e) => {
    let siblings = $("#" + e).siblings(".status-ok");
    if ($(siblings).length == 0) {
        siblings = $("#" + e).prev("span").find(".status-ok")
    }
    if ($(siblings).length == 0) {
        siblings = $("#" + e).parent("fieldset").find(".status-ok")
    }
    if ($(siblings).length == 0) {
        siblings = $("#" + e).siblings("label").find(".status-ok")
    }

    $(siblings).toggleClass('status-ok--show');
    setTimeout(() => {
        $(siblings).toggleClass('status-ok--show');
    }, 500)

}


const _indexOfArrayElement = (array, value) => {
    var indexHit = -1;
    if (array == undefined || array.length == 0) {
        return indexHit;
    }
    array.map((element, index) => {
        if (element != undefined && element.kodeverdi != undefined && element.kodeverdi == value) {
            indexHit = index;
        }
    })
    return indexHit;
}

const _isAlreadyChecked = (store, value) => {
    var checked = false;
    if (store == undefined || store.length == 0) {
        return checked;
    }

    store.map((element, index) => {
        if (element != undefined && element.kodeverdi != undefined && element.kodeverdi == value) {
            checked = true;
        }
    })
    return checked;
}

const _getFileName = (filename) => {
    var splitted = filename.split(".");
    var namePart = splitted.slice(0, splitted.length - 1);
    return namePart.join(".")
}

const _getFileNameFromPath = (path) => {
    if (path == null) {
        return null;
    }
    var encoded = _decodeUrl(path);
    var splitted = encoded.split("/");
    return splitted[splitted.length - 1];
}

const _getFileExtention = (filename) => {
    var splitted = filename.split(".");
    return splitted[splitted.length - 1].toUpperCase();
}

const _decodeUrl = (path) => {
    return decodeURIComponent(path);
}



const _getDataTypeFromFunctionName = (functionName) => {
    if (functionName != undefined) {
        switch (functionName) {
            case "text":
            case "textLarger":
                return "Tekst";
            case "email":
                return "Epostadresse";
            case "postnr":
                return "4 siffer";
            case "numericOnly":
                return "Tall";
            case "boligNr":
                return "Formatet H1234";
            case "date":
                return "Dato dd.mm.yyy";
            case "orgnr":
                return "Organisasjonsnummer, 11 siffer";
        }
    }
}

const _sliceCodeValue = (value) => {
    if (value == undefined)
        return;
    let hasprefix = value.indexOf("_");
    if (hasprefix != -1)
        return value.slice(2);
    return value;
}

// const _isDateObject = date => Object.prototype.toString.call(date) == '[object Date]';

// const _formatDateTimeLocale = (date) => {
//     if (_isDateObject(date)) {
//         return date.toLocaleString("nb-NO");
//     } else if (!isNaN(Date.parse(date))) {
//         return new Date(date).toLocaleString("nb-NO")
//     }
// }

// const _formatDateLocale = (date) => {
//     try{
//         if (_isDateObject(date)) {
//             return date.toLocaleDateString("nb-NO");
//         } else if (!isNaN(Date.parse(date))) {
//             return new Date(date).toLocaleDateString("nb-NO")
//         }
//     }catch(err){}
    
// }

// const _toDateStrTimeAdjusted = (date) => {
//     if (date == undefined) return true
//     try {
//         if (!_isDateObject(date))
//             date = new Date(date)
//         if (_isDateObject(date)) {
//             date.setHours(date.getHours() + 1)
//             return date.toLocaleString("nb-NO");
//         }
//     } catch(err){ }

// }

// const _isDefaultDate = (date) => {
//     if (date == undefined) return true
//     if (_isDateObject(date)) {
//         return date.getTime() == new Date("1800-01-01T00:00:00").getTime()
//     } else {
//         date = new Date(date);
//         if (_isDateObject(date))
//             return date.getTime() == new Date("1800-01-01T00:00:00").getTime()
//         return false;
//     }
// }

// const _toShortDateStr = (date) => {
//     if (date == undefined) return ""
//     if (_isDateObject(date)) {
//         return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
//     }else{
//         date = new Date(date);
//         if (_isDateObject(date))
//             return `${("0"+date.getDate()).slice(-2)}.${("0"+(date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`
//     }

//     return ""
// }

const _makeid = (length) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

const _hash = (valueToHash) => {
    let hashValue = 0, i, chr;
    const check = String(valueToHash);


    if (check === 0) return String(hashValue);
    for (i = 0; i < check.length; i++) {
        chr = valueToHash.charCodeAt(i);
        hashValue = ((hashValue << 5) - hashValue) + chr;
        hashValue |= 0; // Convert to 32bit integer
    }
    return String(hashValue);
}

const _randomString = (length) => {
    return Math.random().toString(36).substring(length || 7);
}

const _sleep = async (ms) => {
    return new Promise(r => setTimeout(r, ms))
}

// export { _isDateObject as isDateObject };
// export { _formatDateLocale as formatDateLocale };
export { _getUrlParameter as getUrlParameter };
export { _setUrlParameter as setUrlParameter };
export { _setUrlParameters as setUrlParameters };
export { _splitUrlParams as splitUrlParams };
export { _buildUrlParams as buildUrlParams };
export { _removeParamsFromUrl as removeParamsFromUrl };
export { _updateComplete as updateComplete };
export { _indexOfArrayElement as indexOfArrayElement };
export { _isAlreadyChecked as isAlreadyChecked };
export { _getFileName as getFileName };
export { _getFileExtention as getFileExtention };
export { _decodeUrl as decodeUrl };
export { _getFileNameFromPath as getFileNameFromPath };
export { _simpleSpinner as simpleSpinner };
export { _getDataTypeFromFunctionName as getDataTypeFromFunctionName };
export { _sliceCodeValue as sliceCodeValue };
export { _makeid as makeId };
export { _hash as hash };
export { _randomString as randomString };
export { _sleep as sleep };