import sanitize from "sanitize-filename";
export const IsHtml = (text: string) : boolean => {
    return /<\/?[a-z][\s\S]*>/i.test(text)
}

export const CleanFilename = (filename: string) : string => {
    if(! filename || typeof filename !== "string") return ""
    const sanitized = sanitize(filename)
    const stripped = sanitized.replaceAll("..", ".").replaceAll(/  +/g, ' ').replaceAll("%", "")
    return stripped.replace(" .", ".")
}

export const CleanText = (text: string) : string => {
    if(! text || typeof text !== "string") return ""
    const cleaned = text.replace(/[\u200B-\u200D\uFEFF]/g, '')
    return cleaned
}

// limit text by set parameter length 
export const LimitText = (text: string, length: number, skipEndingDots: boolean = false) : string => {
    if(! text || typeof text !== "string") return ""
    if(text.length <= length) return text
    return `${text.substring(0, length)}${skipEndingDots ? "" : "..."}`
}

/**
 * 
 * @param stringNumber Hovedsaklig brukt for å sortere ID'er fra sjekklister
 * @returns 
 */
export const PadStringNumberForComparison = (stringNumber: string)  => {
    // Split the id into integer and decimal parts
    if(! stringNumber || typeof stringNumber !== "string") return ""
    if(!stringNumber.includes(".")) return stringNumber
    let [integerPart, decimalPart = ''] = stringNumber.split('.');
    // Ensure the decimal part has at least 2 digits (pad with zeros if necessary)
    integerPart = integerPart.padStart(10, '0');
    decimalPart = decimalPart.padStart(2, '0');
    // Return the formatted string
    return `${integerPart}.${decimalPart}`;
}