import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { appInsights } from "../AppInsightsInit";

const storageKey = "appVersion"
class ErrorBoundaryTopLevel extends React.Component<React.PropsWithChildren , {
    hasError: boolean,
    appVersion: string,
    checkingVersion: boolean,
    needReload: boolean,
    latestVersion: string
}> {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            checkingVersion: true,
            appVersion: sessionStorage.getItem(storageKey),
            needReload: false,
            latestVersion: ""
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log(error)
        return { hasError: true };
    }



    semverGreaterThan = (versionA, versionB) => {
        const versionsA = versionA.replace(/^\D+/g, '')
        if (!versionB) {
            sessionStorage.setItem("appVersion", versionA)
            return false
        }
        const versionsB = versionB.replace(/^\D+/g, '')
        const a = Number(versionsA);
        const b = Number(versionsB);
        return a > b || isNaN(b);
    };
    compareCacheVersionToMetaVersion = async () => {
        fetch('/meta.json')
            .then((response) => response.json())
            .then(async (meta) => {
                const latestVersion = meta.version;
                const currentVersion = this.state.appVersion;
                const shouldForceRefresh = this.semverGreaterThan(latestVersion, currentVersion);
                await this.setState({ latestVersion, checkingVersion: false })
                if (shouldForceRefresh) {
                    await this.setState({ needReload: true })
                    alert(`MAKS-søk er oppdatert - ${latestVersion}. Siden lastes på nytt for å aktivere endringene.`);
                    sessionStorage.setItem("appVersion", latestVersion)
                    window.location.reload();
                } else {
                    // console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);

                }
            })
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, errorInfo);
        appInsights.trackException(error, {
            errorInfo: errorInfo
        })
        console.log(error, errorInfo)
        this.compareCacheVersionToMetaVersion()
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            if (this.state.checkingVersion) {
                return (
                    <div>
                        <h1>Vi sjekker...</h1>
                    </div>
                )
            } else if (this.state.needReload) {
                return (
                    <div>
                        <div>{`MAKS-søk er oppdatert - ${this.state.latestVersion}. Last siden på nytt for å aktivere endringene.`}</div>
                        <Button onClick={() =>window.location.reload()}>Last siden på nytt</Button>
                    </div>
                )
            } else {
                return (
                    <div>
                        <h1>Noe gikk galt.</h1>
                        <p>Hvis problemet fortsetter ta kontakt med Arkitektbedriftene.</p>
                        <Button onClick={() => window.location.reload()}>Last siden på nytt</Button>
                    </div>
                )
            }

        }

        return this.props.children;
    }
}

export default ErrorBoundaryTopLevel