import { Toast } from "react-hot-toast";
import styles from './styles.module.scss'
import { useNotifications } from "../../../contexts/NotificationContextProvider";
import { IoClose } from "react-icons/io5";
import { useEffect, useState } from "react";
const ErrorNotification = ({ toast: t }: { toast: Toast }) => {

    const {
        removeToast
    } = useNotifications()
    const [entering, setEntering] = useState(true)
    const [exiting, setExiting] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setEntering(false)
        }, 100)
    }, [])

    return (
        <div className={styles.item} role="alert">
            <div className={`${styles.message} ${entering ? styles.itemEntering : ""} ${exiting ? styles.itemExiting : ""}`}>{t.message.toString()}</div>
            {!exiting &&
                <IoClose className={styles.close} onClick={() => {
                    setExiting(true)
                    setTimeout(() => {
                        removeToast(t.id)
                    }, 300)
                }} />
            }
        </div>
    )
}

export default ErrorNotification;
