import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import styles from './styles/colors.module.scss'

const InactivityDialog = () => {
    const [show, setShow] = useState(false)
    const [time, setTime] = useState(0)
    const lastClosed = useRef<Date|undefined>(undefined)
    useEffect(() => {
        document.addEventListener("inactivityEvent", async (e: CustomEvent<number>) => {
            if(lastClosed.current) {
                const now = new Date()
                const diff = now.getTime() - lastClosed.current.getTime()
                if(diff < 1000 * 30) {
                    return
                }
            }
            setShow(true)
            setTime(e.detail)
            
        })

        return () => {
            document.removeEventListener("inactivityEvent", () => { })
        }
    }, [])
    if (!show) return null
    const inactivity = time / 1000
    const minutes = Math.floor(inactivity / 60)
    return (
        <div style={{
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',

            backgroundColor: styles.backgroundColorAlt2,
            color: styles.toastColor,
            padding: '20px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <h1>Er du fortsatt der?</h1>
            <p>Du har vært inaktiv i {minutes} minutter. Det anbefales at du laster siden på nytt før du fortsetter.</p>
            <div>
                <Button size="sm" style={{margin: ".5em"}} onClick={() => {
                    setShow(false)
                    lastClosed.current = new Date()
                }}>Lukk meldingen</Button>
                <Button size="sm" style={{margin: ".5em"}} onClick={() => {
                    window.location.reload()
                }}>Last siden på nytt</Button>
            </div>
        </div>
    )
}

export default InactivityDialog;