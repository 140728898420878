import { observable, action, computed, toJS, decorate } from 'mobx';
import { getUrlParameter } from '../utilities/utilityFunc';
import Api from './Api';
import { ApiSuccess, ApiError } from '../utilities/AjaxInterceptors';
import { server } from '../interfaces/types';
import LogEmailService from '../utilities/LogEmailService';
import AppStore from './AppStore';


class FileStore {
    private static _instance: FileStore
    constructor() {

    }

    static instance = (createNew: boolean = false): FileStore => {
        if (!FileStore._instance || createNew)
            FileStore._instance = new FileStore()
        return FileStore._instance
    }
    //Object storing all attachments
    // attachments = new Map<string, Array<server.AttachmentViewModel>>();

    // private sortAttachmentList = (list: Array<server.AttachmentViewModel>): Array<server.AttachmentViewModel> => {
    //     if (list != undefined && Array.isArray(list)) {
    //         return list.slice().sort((a, b) => {
    //             if (a.AttachmentFileName < b.AttachmentFileName) return -1
    //             if (a.AttachmentFileName > b.AttachmentFileName) return 1
    //             return 0;
    //         })
    //     }
    // }
    //Returns all attachments for group
    // attachmentList = (group: string): Array<server.AttachmentViewModel> => {
    //     if (this.attachments.has(group))
    //         return this.sortAttachmentList(this.attachments.get(group));
    //     return null;
    // }

    // getAttachmentsInNabovarsel = (): Array<server.AttachmentViewModel> => {
    //     let list: server.AttachmentViewModel[] = new Array();
    //     let keys = this.attachments.keys();
    //     for (let k of keys) {
    //         if (this.attachments.get(k) != undefined) {
    //             this.attachments.get(k).forEach((att: server.AttachmentViewModel) => {
    //                 if (att.IncludeInNabovarsel) {
    //                     list.push(att);
    //                 }
    //             })
    //         }
    //     }
    //     return list;
    // }

    // updateAttachmentInfo = (group: string, file: server.AttachmentViewModel): Promise<any> => {
    //     return new Promise((resolve, reject) => {
    //         let formData = new FormData();
    //         formData.append("group", group);
    //         formData.append("nid", file.BelongsToNode.toString())
    //         formData.append("file", JSON.stringify(file));
    //         formData.append("attachmentInfo", "attachmentInfo")
    //         Api.action("file").params(null).method("POST")
    //             .data(formData)
    //             .success(() => {
    //                 ApiSuccess("Endringen er lagret")
    //                 LogEmailService.LogChange({
    //                     what: "Oppdatert vedleggsinfo",
    //                     target: group,
    //                     newValue: `Navn: ${file.AttachmentFileName}, Type: ${file.AttachmentType}, Beskrivelse: ${file.Description}`,
    //                     type: "object"
    //                 })
    //             })
    //             .error((err: Error) => {
    //                 LogEmailService.LogChange({
    //                     what: "Kunne ikke oppdatere vedleggsinfo",
    //                     target: group,
    //                     newValue: `Navn: ${file.AttachmentFileName}, Type: ${file.AttachmentType}, Beskrivelse: ${file.Description}`,
    //                     type: "error",
    //                     error: err
    //                 })
    //                 reject(err)
    //             })
    //     })
    // }

    // updateAttachmentType = (group: string, file: server.AttachmentViewModel, currentValueSelected: string): Promise<any> => {
    //     return new Promise((resolve, reject) => {
    //         let formData = new FormData();
    //         formData.append("group", group);
    //         formData.append("nid", file.BelongsToNode.toString())
    //         formData.append("fileId", file.AttachmentFileId);
    //         formData.append("update", "update")
    //         formData.append("attachmentType", currentValueSelected)

    //         Api.action("file").params(null).method("POST")
    //             .data(formData)
    //             .success(action(() => {
    //                 let list = this.attachmentList(group);
    //                 if (list) {
    //                     list.map((f) => {
    //                         if (f.AttachmentFileId == file.AttachmentFileId) {
    //                             f.AttachmentType = currentValueSelected;
    //                             ApiSuccess("Endringen er lagret")
    //                             LogEmailService.LogChange({
    //                                 what: "Oppdatert vedleggstype",
    //                                 target: group,
    //                                 newValue: `Navn: ${f.AttachmentFileName}, Type: ${f.AttachmentType}`,
    //                                 type: "object"
    //                             })
    //                             resolve(null)
    //                         }
    //                     })
    //                 }
    //             }))
    //             .error((err: Error) => {
    //                 LogEmailService.LogChange({
    //                     what: "Kunne ikke oppdatere vedleggstype",
    //                     target: group,
    //                     newValue: `Navn: ${file.AttachmentFileId}, Type: ${currentValueSelected}`,
    //                     type: "error",
    //                     error: err
    //                 })
    //                 reject(err)
    //             })
    //     })
    // }


    // deleteGroupAttachmentFromServer = (group: string, file: server.AttachmentViewModel): Promise<any> => {
    //     return new Promise((resolve, reject) => {
    //         var formData = new FormData();
    //         formData.append("group", group);
    //         formData.append("nid", file.BelongsToNode.toString())
    //         formData.append("blob", "blob")
    //         formData.append("delete", "delete")
    //         formData.append("url", file.AttachmentStorageUrl);
    //         formData.append("fileId", file.AttachmentFileId);

    //         Api.action("file").params(null).method("POST")
    //             .data(formData)
    //             .success(action(() => {
    //                 if (this.attachments != undefined && this.attachments != null) {
    //                     var list = this.attachmentList(group);
    //                     list.map(action((f, index) => {
    //                         if (f.AttachmentFileId == file.AttachmentFileId) {
    //                             list.splice(index, 1);
    //                             ApiSuccess("Vedlegget er slettet")
    //                             LogEmailService.LogChange({
    //                                 what: "Slettet vedlegg fra gruppe",
    //                                 target: group,
    //                                 newValue: `Navn: ${f.AttachmentFileName}`,
    //                                 type: "object"
    //                             })
    //                             this.attachments.set(group, list)
    //                             resolve(null)
    //                         }
    //                     }))
    //                 }
    //             }))
    //             .error((err: Error) => {
    //                 LogEmailService.LogChange({
    //                     what: "Slettet vedlegg fra gruppe",
    //                     target: group,
    //                     newValue: `Navn: ${file.AttachmentFileId}`,
    //                     type: "error",
    //                     error: err
    //                 })
    //                 reject(err)
    //             })
    //     })

    // }

    // setIncludeInNabovarsel = (group: string, AttachmentFileId: string, value: boolean) => {
    //     return new Promise((resolve, reject) => {
    //         var formData = new FormData();
    //         formData.append("attachmentFileId", AttachmentFileId);
    //         formData.append("value", JSON.stringify(value));
    //         if (group != undefined) {
    //             formData.append("group", group);
    //         }
    //         formData.append("nid", getUrlParameter("nid") as string)
    //         formData.append("includeNabovarsel", "includeNabovarsel")
    //         Api.action("file").params(null).method("POST")
    //             .data(formData)
    //             .success((updated: string) => {
    //                 const updatedViewModel = JSON.parse(updated)
    //                 let list = this.attachmentList(group);
    //                 if (list) {
    //                     list.map(action((file: server.AttachmentViewModel) => {
    //                         if (file.AttachmentFileId == AttachmentFileId) {
    //                             file.IncludeInNabovarsel = value;
    //                             if (updatedViewModel && updatedViewModel.AttachmentStorageUrl.length > 0) {
    //                                 file.AttachmentStorageUrl = updatedViewModel.AttachmentStorageUrl
    //                                 file.BelongsToNode = updatedViewModel.BelongsToNode
    //                             }

    //                             ApiSuccess("Endringen er lagret");
    //                             LogEmailService.LogChange({
    //                                 what: "Vedlegg inkludert i nabovarsel",
    //                                 target: group,
    //                                 newValue: `Navn: ${file.AttachmentFileName}, Inkludert: ${value}`,
    //                                 type: "object"
    //                             })
    //                         }
    //                     }))
    //                 }
    //                 resolve(null)
    //             })
    //             .error((err: Error) => {
    //                 LogEmailService.LogChange({
    //                     what: "Kunne ikke sette vedlegg inkludert i nabovarsel",
    //                     target: group,
    //                     newValue: `Navn: ${AttachmentFileId}, Inkludert: ${value}`,
    //                     type: "error",
    //                     error: err
    //                 })
    //                 reject(err)
    //             })
    //     })

    // }

    // useSignedAnsvarAsAttachment = (group: string, signedAnsvar: server.SignedAnsvarsrettAttachment): Promise<any> => {
    //     return new Promise((resolve, reject) => {
    //         Api.action("useSignedAnsvarAsAttachment")
    //             .params(undefined).method("POST")
    //             .data({
    //                 group: group,
    //                 signedAnsvar: signedAnsvar
    //             })
    //             .success((data: any) => {
    //                 this.saveAttachmentsToStore(group, data);
    //                 LogEmailService.LogChange({
    //                     what: "Signert erkæring lagt til som vedlegg",
    //                     target: group,
    //                     newValue: `Navn: ${signedAnsvar.Ansvar}, NodeId: ${signedAnsvar.AnsvarNodeId}`,
    //                     type: "object"
    //                 })
    //                 resolve(data)
    //             })
    //             .error((err: Error) => {
    //                 LogEmailService.LogChange({
    //                     what: "Signert erkæring lagt til som vedlegg - FEIL",
    //                     target: group,
    //                     newValue: `Navn: ${signedAnsvar.Ansvar}, NodeId: ${signedAnsvar.AnsvarNodeId}`,
    //                     type: "error",
    //                     error: err
    //                 })
    //                 reject(err)
    //             })
    //     })
    // }

    /**
     * Save attachment to server and Azure Blob Storage.
     * 
     */
    // saveAttachmentToServer = (group: string, attachment: File, attachmentType: string, nodeId?: string): Promise<any> => {
    //     return new Promise((resolve, reject) => {
    //         var formData = new FormData();
    //         formData.append(attachment.name, attachment);
    //         if (group != undefined) {
    //             formData.append("group", group);
    //         }
    //         formData.append("qpid", getUrlParameter("qpid") as string)
    //         formData.append("nid", nodeId || getUrlParameter("nid") as string)
    //         formData.append("blob", "blob")
    //         formData.append("add", "add")
    //         if (attachmentType) {
    //             formData.append("mime-type", attachment.type)
    //         }

    //         Api.action("file").params(null).method("POST")
    //             .data(formData)
    //             .success((data: string) => {
    //                 this.saveAttachmentsToStore(group, JSON.parse(data));
    //                 LogEmailService.LogChange({
    //                     what: "Lagret vedlegg til server",
    //                     target: group,
    //                     newValue: `Navn: ${attachment.name}, Type: ${attachmentType}, Size: ${attachment.size}`,
    //                     type: "object"
    //                 })
    //                 resolve(null)
    //             })
    //             .error((err: Error) => {
    //                 LogEmailService.LogChange({
    //                     what: "Kunne ikke lagre vedlegg til server",
    //                     target: group,
    //                     newValue: `Navn: ${attachment.name}, Type: ${attachmentType}`,
    //                     type: "error",
    //                     error: err
    //                 })
    //                 reject(err)
    //             })
    //     })

    // }

    addSignedErklaering = (attachment: File, attachmentType: string, nodeId: number, signDate: Date, selectedPhase: string, selectedSoknad: string, avsluttet?: boolean): Promise<string> => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append(attachment.name, attachment);
            formData.append("qpid", getUrlParameter("qpid") as string)
            formData.append("nid", nodeId.toString())
            formData.append("signDate", signDate.toUTCString())
            formData.append("manualSignErklaering", "")
            formData.append("selectedPhase", selectedPhase)
            formData.append("selectedSoknad", selectedSoknad)
            formData.append("avsluttet", String(avsluttet || false))
            if (attachmentType) {
                formData.append("mime-type", attachment.type)
            }

            Api.action("file").params(null).method("POST")
                .data(formData)
                .success((url: string) => {
                    resolve(url)
                })
                .error((err: Error) => {
                    reject(err)
                })
        })

    }


    // saveAttachmentsToStore = action((group: string, files: Array<server.AttachmentViewModel>): void => {
    //     var arr = Array<server.AttachmentViewModel>();
    //     //Group has not been used before
    //     if (!this.attachments.has(group)) {
    //         files.map((file) => {
    //             arr.push(file);
    //         })
    //         this.attachments.set(group, arr)
    //     } else {
    //         arr = this.attachments.get(group)
    //         files.map((file, index) => {
    //             arr.push(file)
    //         })
    //     }
    // })

    // fileNameExistsInGroup = (filename: string, group: string): boolean => {
    //     if (!this.attachments.has(group))
    //         return false;
    //     let arr = this.attachments.get(group);
    //     // let index =  arr.map((file) => {return file.AttachmentFileName}).indexOf(filename)
    //     let index = arr.findIndex((file) => file.AttachmentFileName == filename)
    //     return index != -1 ? true : false
    // }


    getAttachmentFromServer = (url: string, fileName: string, group?: string, qpid?: number, nid?: number) => {

        Api.getAttachment(url, fileName, (error: string) => {
            console.log(error)
            ApiError(error)
        }, qpid, nid);

    }
    // getAttachmentOnArCode = (arcode: string): Promise<server.VedleggWithArCodeMap> => {
    //     return new Promise((resolve, reject) => {
    //         var formData = new FormData();
    //         formData.append("listVedlegg", "listVedlegg");
    //         formData.append("arcode", arcode);

    //         Api.action("file").params(null).method("POST")
    //             .data(formData)
    //             .success((data: string) => {
    //                 resolve(JSON.parse(data))
    //             })
    //             .error((err: Error) => {

    //                 reject(err)
    //             })
    //     })
    // }
    getAttachmentFromServerAsFile = async (url: string, fileName: string, group?: string, qpid?: number, nid?: number) : Promise<File> => {

        return await Api.getAttachmentFile(url, fileName, (error: string) => {
            console.log(error)
            ApiError(error)
        }, qpid, nid);

    }
    
    getEncodedAttachmentFromServer = (attachmentId: string, nid?: string): Promise<server.AttachmentEncoded> => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("getEncoded", "getEncoded");
            formData.append("attachmentId", attachmentId);
            formData.append("nid", nid || getUrlParameter("nid") as string)

            Api.action("file").params(null).method("POST")
                .data(formData)
                .success((data: string) => {
                    // console.log("Attachment list arrived::")
                    // console.log("Num attachments: " + $.parseJSON(data).length);
                    resolve(JSON.parse(data))
                })
                .error((err: Error) => {
                    LogEmailService.LogChange({
                        what: "getEncodedAttachmentFromServer",
                        newValue: `Error`,
                        type: "error",
                        error: err
                    })
                    reject(err)
                })
        })
    }

    getAttachmentIds = (): Promise<server.AttachmentIdsAndNames[]> => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("attachmentIdList", "attachmentIdList");
            formData.append("nid", getUrlParameter("nid") as string)

            Api.action("file").params(null).method("POST")
                .data(formData)
                .success((data: string) => {
                    // console.log("ATTACHMENTIDs", JSON.parse(data))
                    // console.log("Num attachments: " + $.parseJSON(data).length);
                    const ids: server.AttachmentIdsAndNames[] = JSON.parse(data)
                    const sorted = ids.sort((a, b) => {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    })
                    resolve(sorted)
                })
                .error((err: Error) => {
                    reject(err)
                })
        })
    }



    /**
    * Use optional nid parameter if saving to different node than current soknad
    */
    savePdfToServer = (message_self: string, blob: Blob, signed: boolean, nid: string): Promise<any> => {
        // let blobFile = new Blob([blob],{type: blob.type})
        let file = blob as any
        file.name = "pdfarchived.pdf"
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("pdfarchived", file as File);
            formData.append("nid", nid || getUrlParameter("nid") as string)
            formData.append("qpid", getUrlParameter("qpid") as string)
            formData.append("blob", "blob")
            formData.append("pdf", "pdf")
            formData.append("messageId", message_self);
            formData.append("signed", JSON.stringify(signed));
            Api.action("file").params(null).method("POST")
                .data(formData)
                .success((link: string) => {
                    console.log("PDF-link", link)
                    LogEmailService.LogChange({
                        what: "Lagret PDF til server",
                        target: nid,
                        newValue: `MessageId: ${message_self}`,
                        type: "object"
                    })
                    resolve(link);
                })
                .error((err: Error) => {
                    LogEmailService.LogChange({
                        what: "Kunne ikke lagre PDF til server",
                        target: nid,
                        newValue: `MessageId: ${message_self}`,
                        type: "error",
                        error: err
                    })
                    reject(err)
                })
        })

    }

    uploadSignedErklaering = (blob: Blob, signDate: Date, nid: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("pdfarchived", new File([blob], "pdfarchived.pdf", { type: blob.type }));
            formData.append("nid", nid || getUrlParameter("nid") as string)
            formData.append("qpid", getUrlParameter("qpid") as string)
            formData.append("signDate", signDate.toISOString());
            formData.append("signErklaering", "signErklaering");
            Api.action("file").params(null).method("POST")
                .data(formData)
                .success((link: string) => {
                    console.log("PDF-link", link)
                    resolve(link);
                })
                .error((err: Error) => {
                    reject(err)
                })
        })

    }



    /**
     * Save XML forms in azure blob storage.
     */
    saveXmlToServer = (xml: XMLDocument | XMLDocument[], nid: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            var formData = new FormData();
            formData.append("nid", nid || getUrlParameter("nid") as string)
            formData.append("xmlForms", JSON.stringify(xml))
            Api.action("file").params(null).method("POST")
                .data(formData)
                .success(() => {
                    console.log("Xml saved in Azure")
                    //LogEmailService.LogEvent(`Nedlastet XML av innsendt søknad er lagret i Azure`)
                    resolve(true);
                })
                .error((err: Error) => {
                    LogEmailService.LogChange({
                        error: err,
                        type: "error",
                        what: `Kunne ikke lagre nedlastet XML`
                    })
                    reject(err)
                })
        })

    }

    // //Convert attachment objects from server format to our chosen format as map()
    // convertAttachmentObjectFromServer = (fromServer: Array<server.AttachmentGroup>): void => {
    //     if (fromServer && fromServer.length > 0)
    //         fromServer.map((obj, index) => {
    //             this.attachments.set(obj.GroupName, obj.Attachments)
    //         })
    // }

}

export default FileStore
