import React, { FC } from "react"

interface Props extends React.PropsWithChildren {
    fallback?: JSX.Element
    children?: React.ReactNode|JSX.Element
}
const AbSuspense : FC<Props> = (props: Props) => {

    return (
        <React.Suspense fallback={props.fallback ? props.fallback : <div></div>}>
            {props.children}
        </React.Suspense>
    )
}

export default AbSuspense