import { Altinn } from "../interfaces/SharedTypes";
import { hash } from "../utils/Utils";
import { default as LegacyApi } from "../legacy/stores/Api";
import { AltinnSendFileUpload } from "../interfaces/models/altinn/AltinnSendEvent";
import { CleanFilename } from "../utils/TextUtils";
import { altinnUserManager } from "../Auth";
import toast from "react-hot-toast";
import DateUtils from "../utils/DateUtils";
interface Headers {
    [key: string]: string
}
class AltinnApi {
    _settings: Altinn.Common.Settings
    constructor(settings: Altinn.Common.Settings) {
        let settingsCopy = { ...settings }
        settingsCopy.messages = settingsCopy.messages || {
            skip: 0,
            pageSize: 10,
            status: "Utfylling",
            serviceOwner: "Direktoratet for byggkvalitet",
            messageType: "FormTask",
            soknadType: "ALLE"
        }
        this._settings = settingsCopy
    }
    private _AltinnApi = async (uri: string, method?: "GET" | "POST" | "PUT" | "DELETE", postData?: any, extraHeaders?: Headers, annonymous: boolean = false): Promise<Response> => {
        let _headers = {...this._settings.headers}
        if(annonymous === false) {
            const altinnToken = await altinnUserManager?.getUser()
            if(altinnToken?.expired) {
                const user = await altinnUserManager?.signinSilent()
                if(!user) altinnUserManager.signoutSilent()
            }
            if(!altinnToken?.access_token) throw Error("Altinn token not found")
            _headers.Authorization = "Bearer " + altinnToken.access_token
        }
        const url = this.BuildUrlBase(uri)
        const _method = method || "GET"
        if (extraHeaders)
            _headers = Object.assign(_headers, extraHeaders)
        const response = await fetch(url, {
            headers: _headers,
            method: _method,
            body: postData ? postData : null
        })
        if (!response?.ok) {
            if(response?.status == 401) {
                toast.error("Altinn token er utløpt. Du må logge inn på nytt om du skal sende søknader.")
                await altinnUserManager.removeUser()
            }else
                return await this.InspectFetchApiError(response, uri, method, postData, extraHeaders)

        }
        if(response?.headers?.has("X-Warning-LimitReached")){
            toast("Filtrering av Altinn-innboksen har nådd grensen for antall meldinger. Vennligst filtrer på en mindre tidsperiode eller søk etter en spesifikk søknadstype for å se flere meldinger.")
        }
        return response

    }
    BuildUrlBase = (uri: string): string => {
        let url = ""
        if (uri.startsWith(this._settings.apiBaseUrl)) url = uri
        else url = this._settings.apiBaseUrl + "/api" + uri
        return url
    }
    GetAltinnMetadata = async (serviceCode: string, serviceEditionCode: string): Promise<Altinn.Get.AttachmentMetadataDto> => {
        const res = await this._AltinnApi(`/metadata/formtask/${serviceCode}/${serviceEditionCode}?language=1044`, "GET", null, null, true);
        return res.json()
    }

    InspectFetchApiError = async (response: Response, uri: string, method?: string, postData?: any, extraHeaders?: Headers) => {
        if (!response) {
            throw new Error("En feil har skjedd mot Altinn. Det kan hende du må logge inn på nytt.")
        }
        let errorObj: Altinn.Common.AltinnCustomException = {
            objectId: "altinnCustomException",
            status: response.status,
            statusText: response.statusText || "",
            statusTextDetails: null,
            statusTextDetailsIsArray: false
        }
        const text = await response.text()
        let json = null
        try {
            json = JSON.parse(text)

        } catch { }
        if (json != null) {
            const hasValidationErrors = json.hasOwnProperty("ValidationErrors")
            if (hasValidationErrors) {
                errorObj.statusTextDetailsIsArray = Array.isArray(json.ValidationErrors)
                errorObj.statusTextDetails = json.ValidationErrors
            } else
                errorObj.statusTextDetails = JSON.stringify(json, null, 2)
        }
        throw errorObj
    }
    InspectJqueryApiError = async (response: JQuery.jqXHR): Promise<Altinn.Common.AltinnCustomException> => {
        let errorObj: Altinn.Common.AltinnCustomException = {
            objectId: "altinnCustomException",
            status: response.status,
            statusText: response.statusText || "",
            statusTextDetails: null,
            statusTextDetailsIsArray: false
        }
        const json = response.responseJSON ? response.responseJSON() : null
        if (json != null) {
            const hasValidationErrors = json.hasOwnProperty("ValidationErrors")
            if (hasValidationErrors) {
                errorObj.statusTextDetailsIsArray = Array.isArray(json.ValidationErrors)
                errorObj.statusTextDetails = json.ValidationErrors
            } else
                errorObj.statusTextDetails = JSON.stringify(json, null, 2)
        }
        return errorObj
    }
    LogInIdPort = () => {
        const ApiBaseUri = this._settings.apiBaseUrl;
        let currentUrl = window.location.href;
        let encodedReturnUrl = encodeURIComponent("returnUrl=".concat(currentUrl));
        let url = ApiBaseUri + "/Pages/ExternalAuthentication/Redirect.aspx?".concat(encodedReturnUrl);
        window.location.href = url;

    }

    /**
     * This function is tested.
     * Retreives a list of Reportees, based on ID Porten login cookie.
     * The logged in user can represent each of these entities in the resulting list
     */
    AltinnGetReportees = async (): Promise<Altinn.Get.ReporteeResult> => {
        return await (await this._AltinnApi("/reportees?showConsentReportees=false&includeInactiveReportees=false&$top=100")).json()
    }
    AltinnGetRoles = async (reportee: string): Promise<Altinn.Get.RoleResult> => {
        return await (await this._AltinnApi("/" + reportee + "/authorization/roles")).json()
    }
    AltinnGetRolesForReportee = async (reporteeHref: string): Promise<Altinn.Get.RoleResult> => {
        return await (await this._AltinnApi(reporteeHref)).json()
    }
    AltinnGetRight = async (serviceCode: number, serviceEditionCode: number): Promise<Altinn.Get.ReporteeResult> => {
        return await (await this._AltinnApi(`/reportees?serviceCode=${serviceCode}&serviceEdition=${serviceEditionCode}&showConsentReportees=false&includeInactiveReportees=false`)).json()
    }
    AltinnGetPrint = async (printUrl: string): Promise<Blob> => {
        return await (await this._AltinnApi(`${printUrl}/print`, "GET", null, { ResponseType: "blob", Accept: "application/pdf" })).blob()
    }
    AltinnDeleteMessage = async (messageId: string): Promise<boolean> => {
        return await (await this._AltinnApi(messageId, "DELETE", null)).ok
    }
    AltinnGetForms = async (messageId: string): Promise<Altinn.Get.FormsResult> => {
        return await (await this._AltinnApi(`${messageId}/forms`, "GET")).json()
    }
    AltinnGetFormsAsXml = async (formsWrapper: Altinn.Get.FormsResult): Promise<XMLDocument[]> => {
        return Promise.all(formsWrapper._embedded.forms.map(f => this.AltinnGetForm(f._links.formdata.href)))
    }
    AltinnGetUploadedAttachments = async (messageId: string): Promise<Altinn.Get.AttachmentResult> => {
        return await (await this._AltinnApi(`${messageId}/attachments`, "GET")).json()
    }
    AltinnGetForm = async (formId: string): Promise<XMLDocument> => {
        const res = await this._AltinnApi(formId, "GET", null, { responseType: "application/xml" })
        return new window.DOMParser().parseFromString(await res.text(), "application/xml")
    }
    AltinnGetMessages = async (representing: string): Promise<Altinn.Get.MessageResult> => {
        const filter = `?$top=${this._settings.messages.pageSize}&$skip=${this._settings.messages.skip}&$filter=ServiceOwner eq '${this._settings.messages.serviceOwner}' and Type eq '${this._settings.messages.messageType}' and Status eq '${this._settings.messages.status}'`
        return await (await this._AltinnApi(`/${representing}/messages/${filter}`, "GET")).json()
    }
    AltinnGetMessagesV2 = async (representing: string, settings: Altinn.Common.MessageSettings, allowedImportTypes: string[]): Promise<Altinn.Get.MessageResult> => {
        let filter = `?$top=${settings.pageSize}&$skip=${settings.skip}&$filter=ServiceOwner eq '${settings.serviceOwner}' and Type eq '${settings.messageType}' and Status eq '${settings.status}'`
        if(settings.soknadType != "ALLE") {
            filter += ` and ServiceCode eq '${settings.soknadType}'`
        }else{
            const q = allowedImportTypes.map(x => `ServiceCode eq '${x}'`).join(" or ")
            filter += ` and (${q})`
        }
        if(DateUtils.isDateObj(settings.dateFrom)) {
            filter += `&dateFrom=${settings.dateFrom.toISOString().split('T')[0]}`
        }
        if(DateUtils.isDateObj(settings.dateTo)) {
            filter += `&dateTo=${settings.dateTo.toISOString().split('T')[0]}`
        }
        return await (await this._AltinnApi(`/${representing}/messages/${filter}`, "GET")).json()
    }
    AltinnInspectReportees = (reportees: Altinn.Get.ReporteeResult, orgnr: string): string => {
        try {
            const match = reportees._embedded.reportees.find(obj => obj.Type != "Person" && obj.OrganizationNumber == orgnr)
            return match?.OrganizationNumber
        } catch (err) {
            throw err
        }
    }
    AltinnGetJson = async (uri: string): Promise<Altinn.Get.Message> => {
        return await (await this._AltinnApi(uri, "GET")).json()
    }
    AltinnGetDelegations = async (reportee: string): Promise<Altinn.Get.DelegationResult> => {
        return await (await this._AltinnApi("/" + reportee + "/authorization/delegations")).json()
    }
    AltinnDeleteRole = async (organization: string, reportee: string, roleId: number): Promise<Response> => {
        return await this._AltinnApi("/" + organization + "/authorization/delegations/" + reportee + "/roles/" + roleId, "DELETE", null)
    }
    AltinnAddRole = async (xml: string, reportee: string): Promise<Response> => {
        return await this._AltinnApi("/" + reportee + "/authorization/delegations", "POST", xml, { "Content-Type": "application/xml" })
    }
    private _FillAltinnRightsWithNames = async (): Promise<Altinn.Get.FormNamesAndCodes[]> => {
        return new Promise((resolve, reject) => {
            LegacyApi.action("getAltinnRightsList").params().method("GET").data()
                .success((fromServer: Altinn.Get.FormNamesAndCodes[]) => resolve(fromServer))
                .error((err: Error) => reject(err))
        })
    }
    AltinnGetRights = async (): Promise<Altinn.Get.FormRight[]> => {

        let rightsList: Altinn.Get.FormRight[] = []
        const skjemaList = await this._FillAltinnRightsWithNames();
        if (skjemaList && Array.isArray(skjemaList)) {
            await Promise.all(skjemaList.map(async skjema => {
                try {
                    const reporteeResult = await this.AltinnGetRight(skjema.serviceCode, skjema.serviceEditionCode);
                    if (reporteeResult && reporteeResult._embedded) {
                        let newRight: Altinn.Get.FormRight = {
                            name: skjema.name,
                            hasRights: []
                        }
                        reporteeResult._embedded.reportees.map(r => {
                            if (r.Type.toLowerCase() == "person") {
                                // console.log("Processing", r)
                                newRight.hasRights.push({ name: r.Name, ident: r.SocialSecurityNumber ? hash(r.SocialSecurityNumber) : r.OrganizationNumber })
                            }

                        })
                        rightsList.push(newRight);
                    }
                } catch (e) {
                    rightsList.push({
                        name: skjema.name,
                        hasRights: [],
                        possibleErrorInObject: skjema
                    })
                }

            }))
            return rightsList;
        }

    }
    AltinnPut = async (messageId: string, complete: boolean, sign: boolean, data: Altinn.Send.PostForm): Promise<string> => {
        let copy = Object.assign({}, data)
        delete copy._embedded;
        const res = await this._AltinnApi(`${messageId}?language=1044&complete=${complete}&sign=${sign}`, "PUT", JSON.stringify(copy), { "Content-Type": "application/hal+json; charset=utf-8" })
        if (res.status == 204) {
            var msg = res.headers.get('location');
            return msg;
        }
    }
    AltinnPost = async (reportee: string, complete: boolean, sign: boolean, data: Altinn.Send.PostForm): Promise<string> => {
        const res = await this._AltinnApi(`/${reportee}/messages?language=1044&complete=${complete}&sign=${sign}`, "POST", JSON.stringify(data), { "Content-Type": "application/hal+json; charset=utf-8" })
        if (res.status == 201) {
            var msg = res.headers.get('Location');
            return msg;
        }
        throw Error(res.statusText)
    }
    AltinnPostFile = async (reportee: string, messageId: string, filename: string, attachmentType: string, file: Blob, attachmentId: string, progressCallback: (fileEvent: AltinnSendFileUpload) => void, cancelAllToken?: boolean): Promise<boolean> => {
        if (cancelAllToken == true) throw Error("Opplasting avbrutt")
        try {
            var filenamelength = filename.length;
            if (filenamelength > 180) {
                var start = filenamelength - 180;
                filename = filename.substring(start);
            }
            const altinnToken = await altinnUserManager?.getUser()
            if(!altinnToken?.access_token) throw Error("Altinn token not found")
            filename = CleanFilename(filename)
            let url = `${messageId}/attachments/streamedattachment?filename=${encodeURIComponent(filename)}&attachmenttype=${encodeURIComponent(attachmentType)}`
            const _headers = Object.assign(this._settings.headers, { 
                "Content-Type": "application/octet-stream",
                "Authorization": "Bearer " + altinnToken?.access_token
            })

            let customEvent = {
                id: attachmentId,
                name: filename,
                percentComplete: 0,
                error: null
            }
            progressCallback(customEvent);
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: "POST",
                    url: url,
                    headers: _headers,
                    xhr: () => {
                        var xhr = new window.XMLHttpRequest();
                        xhr.upload.addEventListener("progress", (evt) => {
                            if (evt.lengthComputable) {
                                var percentComplete = evt.loaded / evt.total;
                                percentComplete = percentComplete * 100;
                                customEvent.percentComplete = percentComplete
                                progressCallback(customEvent);
                            }
                        }, false);

                        xhr.onreadystatechange = () => {
                            if(xhr.status >= 400) {
                                reject(xhr.statusText)
                            }
                        }
                        return xhr;
                    },
                    processData: false,
                    data: file,
                    success: (data, status, jqHxr) => {
                        if (cancelAllToken) reject("Canceled upload in success")
                        resolve(true);
                    },
                    error: async (err) => {
                        reject(await this.InspectJqueryApiError(err))
                    }
                })
            })
        } catch (err) {
            throw err
        }
    }


}
export default AltinnApi
