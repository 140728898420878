import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";


const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_AI_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        disableAjaxTracking: false
    }
});
ai.loadAppInsights();
export const setAiUser = (username: string, firmId: string) => {
    ai.setAuthenticatedUserContext(username, firmId, true)
}
export const appInsights = ai.appInsights
export { reactPlugin }