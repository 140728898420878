import { action, decorate, observable, runInAction, toJS } from "mobx";
import { altinn, dibk, server, view } from "../../interfaces/types";
import { ApiErrorServerLog } from "../../utilities/AjaxInterceptors";
import LogEmailService from "../../utilities/LogEmailService";
import { getUrlParameter, simpleSpinner, sleep } from "../../utilities/utilityFunc";
import Api from "../Api";
import AppStore from "../AppStore";
import FileStore from "../FileStore";
import InnsendingsprosessStore from "../InnsendingsStore";
import { AltinnDeleteMessage, AltinnGetForm, AltinnGetForms, AltinnGetFormsAsXml, AltinnGetJson, AltinnGetMessages, AltinnGetPrint, AltinnGetReportees, AltinnGetRights, AltinnGetRoles, AltinnInspectReportees, AltinnPost, AltinnPostFile, AltinnPut, LogInIdPort } from "./functions/AltinnApi";


class AltinnStore {

    settings: altinn.Settings = {
        apiBaseUrl: "",
        key: "",
        headers: {
            ApiKey: "",
            Accept: 'application/hal+json',
        },
        messages: {
            skip: 0,
            pageSize: 10,
            status: "Utfylling",
            serviceOwner: "Direktoratet for byggkvalitet",
            messageType: "FormTask"
        }
    }
    chosenOrganizationNumber: string = "my"
    reportees: altinn.Reportee[] = null;
    isLoggedInToAltinn = false;
    roles: altinn.RoleResult
    rights: altinn.FormRight[]
    isLoggedIn = () => { return this.isLoggedInToAltinn; }
    set loggedIn(value: boolean) {
        this.isLoggedInToAltinn = value
    }
    public hasAttachments = (form: string) => {
        const hasAttachmentList: string[] = Object.values(AppStore.ctx.constants.hasAttachments);
        if (hasAttachmentList.includes(form))
            return true;
        return false;
    }

    public prepare = async () => {
        const altinnStatus = await this.tryAltinn()
        const inspectedOK = await this.inspectAltinnOk(altinnStatus)
        if (!inspectedOK) sleep(500)
    }
    set reportee(reportee: string) {
        runInAction(() => this.chosenOrganizationNumber = reportee)
    }
    get reportee(): string {
        return this.chosenOrganizationNumber
    }
    get sufficientRoleList(): number[] {
        return AltinnStoreV2.settings.apiBaseUrl.includes("www.altinn.no") ? [95, 28088, 11] : [134, 24448, 11]
    }
    get planByggesakRole(): number {
        return AltinnStoreV2.settings.apiBaseUrl.includes("www.altinn.no") ? 28088 : 24448
    }
    get protectedAltinnRoles(): number[] {//125
        return AltinnStoreV2.settings.apiBaseUrl.includes("www.altinn.no") ? [160, 125] : [195, 160] //daglig leder, styreleder
    }
    get manageAltinnRoles(): number[] {//125
        return AltinnStoreV2.settings.apiBaseUrl.includes("www.altinn.no") ? [160, 125, 28997, 4] : [195, 160, 24869, 4] //daglig leder, styreleder, hovedadministrator, tilgangstyring
    }
    setAltinnParameters = action((altinnPath: string, altinnApiKey: string) => {
        this.settings.apiBaseUrl = altinnPath
        this.settings.headers.ApiKey = altinnApiKey
    })
    public representingReportee = (representing: string) => {
        if (!this.reportees) return "deg"
        return this.reportees.find(x => x.OrganizationNumber == representing)?.Name
    }
    public getReportees = async () => {
        const result = await AltinnGetReportees();
        if (result != null) {
            this.reportees = result._embedded.reportees
            this.reportee = this.inspectReportees(result)
            console.log("Can represent", toJS(this.reportees))
            console.log("Chosen OrgNr", this.reportee);
        }
    }
    public rawReportees = (): altinn.Reportee[] => {
        return this.reportees
    }

    private inspectReportees = (reportees: altinn.ReporteeResult): string => {
        try {
            const orgnr = AppStore.user().OrgNr;
            return AltinnInspectReportees(reportees, orgnr)
        } catch (err) {
            console.log("::: Reportee info :::")
            console.log("Can represent", toJS(this.reportees))
            console.log("Chosen OrgNr", this.reportee);
            return "my";
        }
    }
    public resetLoggedInState = () => {
        this.isLoggedInToAltinn = false
        this.reportees = null
        this.roles = null
        this.rights = null
    }
    public getAltinnRoles = async (): Promise<altinn.RoleResult> => {
        const roles = await AltinnGetRoles();
        runInAction(() => this.roles = roles)
        return roles
    }
    public acceptedRoles = (): altinn.Role[] => {
        if (!this.roles) return []
        return this.roles._embedded.roles.map(action((role: altinn.Role) => {
            if (this.sufficientRoleList.includes(role.RoleDefinitionId)) return role
        }))
    }
    public getAltinnRights = async (): Promise<altinn.FormRight[]> => {
        const rights = await AltinnGetRights();
        runInAction(() => this.rights = rights)
        return rights
    }

    private tryAltinn = async () => {
        try {
            const msgList = await AltinnGetMessages("my")
            if (msgList) return true
            return false
        } catch (err) {
            Promise.reject(false)
        }
    }
    private inspectAltinnOk = async (altinnStatus) => {
        if (!altinnStatus) {
            alert("Du er ikke lenger pålogget Altinn og blir sendt til ID-porten først.")
            LogInIdPort()
            return Promise.reject(false)
        }
        return Promise.resolve(true)
    }

    public getChosenOrganizationNumer = () => { return this.reportee === "my" ? "deg" : this.reportee; }

    downloadXmlFromAltinnUsingArcode = async (reportee, arCode): Promise<altinn.FormImported[]> => {
        const message_self = "/" + reportee + "/messages/" + arCode
        return await this.downloadXmlFromAltinn(message_self)
    }
    downloadXmlFromAltinn = async (messageId): Promise<altinn.FormImported[]> => {

        try {
            const root: altinn.Message = await AltinnGetJson(messageId);
            // console.log(root)
            const pdf = await AltinnGetPrint(root._links.self.href);
            const formsList: altinn.FormImported[] = await Promise.all(root._links.form.map(async f => await AltinnGetJson(f.href)))
            // console.log(formsList)
            await Promise.all(formsList.map(async f => {
                const xml = await AltinnGetForm(f._links.formdata.href).then(x => x)
                f.xml = new XMLSerializer().serializeToString(xml.documentElement)
                if (f.Type == "MainForm")
                    f.pdf = pdf
            }))
            // console.log(formsList)
            return formsList
        } catch (err) {
            // console.log(err)
            throw err
        }
    }


    public logInnsending = async (logName: string, postData: altinn.PostForm, error: any) => {
        const toLog = Api.buildLog().params({ action: logName }).error(error).data(postData)
        Api.action("log").params(null).method("POST").data(toLog)
            .success((serverLog) => {
                // document.dispatchEvent(new CustomEvent("byggsokFileUploadEventClear", ({})))
                simpleSpinner(null, false);
                if (serverLog != undefined && typeof serverLog == "object") {
                    serverLog.title = "Noe gikk galt i overførselen til Altinn. Data ble ikke overført.";
                    serverLog.message = "Feilmeldingen er logget og sendt til Arkitektbedriftene"
                    ApiErrorServerLog(serverLog)
                }
            }).error()
    }
    public getChosenForm = (formType: string, params?: any, nodeId?: number): Promise<server.ServerGeneratedSkjema> => {
        return new Promise((resolve, reject) => {
            let getParams = nodeId != undefined ? { "nid": nodeId } : null
            Api.action("getForm").params(getParams).method("POST")
                .data({
                    formType: formType,
                    params: params
                })
                .success(action((data: server.ServerGeneratedSkjema) => {
                    //console.log(data)
                    resolve(data);
                }))
                .error((err) => {
                    //console.log(err)
                    reject(err);
                })
        })
    }
    public fillFormTaskData = (chosenForm: server.ServerGeneratedSkjema, skipVedleggsopplysninger?: boolean): altinn.PostForm => {
        // console.log("ChosenForm", chosenForm)
        try {
            let postData: altinn.PostForm = {
                Type: "FormTask",
                ServiceCode: chosenForm.ServiceCode,
                ServiceEdition: chosenForm.ServiceEditionCode,
                _embedded: {
                    forms: [
                        {
                            Type: "MainForm",
                            DataFormatId: chosenForm.DataFormatID,
                            DataFormatVersion: chosenForm.DataFormatVersion,
                            FormData: chosenForm.Data
                        }
                    ]
                }
            };

            // //If attachments should be included. ServiceCode 4762 is distribution of ansvarsrett, and does not have attachments.
            // if(chosenForm.ServiceCode != 4762 && this.hasAttachments() == true && this.data.attachmentList != undefined){
            //     postData._embedded.attachments = this.data.attachmentList;
            // }

            //If additional forms should be included.
            if (chosenForm.SkjemaSet != null) {
                chosenForm.SkjemaSet.forEach((subform) => {
                    const skip = skipVedleggsopplysninger == true && subform.Name.toLocaleLowerCase().includes("vedleggsopplysninger")
                    if (!skip) {
                        let namelessSubForm: altinn.PostFormEmbeddedForm = {
                            Type: "SubForm",
                            DataFormatId: subform.DataFormatId,
                            DataFormatVersion: subform.DataFormatVersion,
                            FormData: subform.XmlData
                        }
                        postData._embedded.forms.push(namelessSubForm)
                    }

                })
            }
            // console.log("POSTDATA:")
            // console.log(postData)
            return postData;
        } catch (err) {
            console.log("fillFormTaskData failed", err)
        }


    }


    public getEncodedAttachment = (attachmentId: string, attachmentName: string, cancelAllToken: boolean, nid?: string): Promise<server.AttachmentEncoded> => {
        return new Promise((resolve, reject) => {
            if (cancelAllToken) reject("Canceled getEncoding")
            FileStore.instance().getEncodedAttachmentFromServer(attachmentId, nid)
                .then(action((encodedAttachment) => {
                    if (cancelAllToken) reject("Canceled")
                    resolve(encodedAttachment)
                }))
                .catch((err) => {
                    reject(err)
                })
        })
    }

    public saveMessageIdOnSoknad = (signed: boolean, messageId: string, nid): Promise<void> => {
        // console.log("Saving messageId on Soknad");
        return new Promise((resolve, reject) => {
            Api.action("saveMessageId").params({ nid: nid }).method("POST")
                .data({
                    messageId: messageId,
                    signed: signed
                })
                .success((data) => {
                    //LogEmailService.LogEvent(`Altinn referanse lagret på søknad ${messageId}`)
                    resolve(null)
                })
                .error((err) => {
                    LogEmailService.LogChange({
                        error: err,
                        what: `Kunne ikke lagre Altinn referanse på søknad ${messageId}`,
                        type: "error"
                    })
                    reject(err)
                })
        })
    }
   
    saveAnsvarDirekte = (fagNodeId: number): Promise<number> => {
        return new Promise((resolve, reject) => {
            Api.action("saveAnsvarDirekte").params().method("POST")
                .data({
                    fagNodeId: fagNodeId
                })
                .success((ansvarNodeId) => {
                    resolve(ansvarNodeId)
                })
                .error((jqHxr, textStatus, errorThrown) => {
                    reject(jqHxr)
                })
        })

    }

    exportSoknadToAltinn = async (nodeId: number, reportee?: string, skipVedleggsopplysninger?: boolean): Promise<string> => {
        await this.prepare()
        let postData: altinn.PostForm = null
        const chosenForm = await this.getChosenForm("Soknad", null, nodeId)
        postData = this.fillFormTaskData(chosenForm, skipVedleggsopplysninger);
        return await AltinnPost(reportee || this.reportee, false, false, postData)
    }
    copySoknadViaAltinn = async (nodeId: number): Promise<altinn.FormImported[]> => {
        const messageId = await this.exportSoknadToAltinn(nodeId, null, true)
        const downloaded = await this.downloadXmlFromAltinn(messageId)
        await AltinnDeleteMessage(messageId)
        return downloaded
    }
}
decorate(AltinnStore, {
    settings: observable,
    chosenOrganizationNumber: observable,
    reportees: observable,
    isLoggedInToAltinn: observable,
    roles: observable,
    rights: observable
})
const AltinnStoreV2 = new AltinnStore();
export default AltinnStoreV2