import { ReactNode, createContext, useContext } from "react";
import { NotificationContextInterface } from "./NotificationContextInterface";
import { useErrorToasts } from "../hooks/useErrorToasts";
import NotificationContainer from "../components/library/notification/NotificationContainer";



export const NotificationContext = createContext<NotificationContextInterface | undefined>(undefined);


export const NotificationContextProvider = ({ children }: { children: ReactNode }) => {

    const { errorToasts, addErrorToast, removeErrorToast, clearToasts } = useErrorToasts()
    return (
        <NotificationContext.Provider value={{
            errorToast: addErrorToast,
            toasts: errorToasts,
            removeToast: removeErrorToast,
            clearToasts: clearToasts
        }}>
            <>
            <NotificationContainer />
                {children}
            </>
        </NotificationContext.Provider>
    )
}

export const useNotifications = () => {
    const context = useContext(NotificationContext)
    if (context === undefined) {
        throw new Error('useNotifications must be used within a NotificationContextProvider')
    }
    return context
}