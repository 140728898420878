import React, { FC, useState } from 'react';
import { SidepanelCustomType } from '../interfaces/models/sidepanel/SidepanelCustomType';
import { SidePanelSize } from '../interfaces/models/sidepanel/SidepanelSize';
import { SidePanelContextInterface } from './SidePanelContextInterface';


const storageKey = "SidePanelSize"
export const SidePanelContext = React.createContext<SidePanelContextInterface | undefined>(undefined);

export const SidePanelContextProvider: FC<SidePanelContextInterface> = ({ children }) => {
    const [isOpen, setOpen] = useState<boolean>(false)
    const [closeOnMinimize, setCloseOnMinimize] = useState<boolean>(false)
    const [faqMaximized, setFaqMaximized] = useState<boolean>(false)
    const [faqSlug, setFaqSlug] = useState<string>()
    const [size, setSize] = React.useState<SidePanelSize>(localStorage.getItem(storageKey) as SidePanelSize || "md")
    const [maximizeContent, setMaximizeContent] = React.useState<JSX.Element>()
    const [customElements, setCustomElements] = React.useState<SidepanelCustomType[]>()
    const updateSize = (size: SidePanelSize) => {
        setSize(size)
        localStorage.setItem(storageKey, size)
    }
    return (
        <SidePanelContext.Provider value={{
            faqSlug: faqSlug,
            setFaQSlug: (slug: string) => {
                setFaqSlug(slug)
            },
            faqMaximized: faqMaximized,
            setFaqMaximized: (value: boolean) => {
                setFaqMaximized(value)
            },
            isOpen: isOpen,
            setIsOpen: (open: boolean) => {
                setOpen(open)
            },
            maximizedContent: maximizeContent,
            setMaximizedContent: (content: JSX.Element, external: boolean = false) => {
                if (!external) {
                    setMaximizeContent(content)
                    setCloseOnMinimize(false)
                } else {
                    setOpen(true)
                    setSize('lg')
                    setMaximizeContent(content)
                    setCloseOnMinimize(true)
                }
            },
            width: size,
            setWidth: (width: SidePanelSize) => {
                setSize(width)
            },
            addCustomElement: (element: SidepanelCustomType) => {
                if (element.openOnAdd) setOpen(true)
                setSize(element.sidePanelSize)
                setCustomElements(prev => {
                    const copy = prev ? [...prev] : []
                    const idx = copy.findIndex(x => x.title === element.title)
                    if(idx === -1)
                        copy.push(element)
                    else {
                        copy[idx].content = element.content
                    }
                    return copy
                })
            },
            removeCustomElement: (title: string) => {
                setCustomElements(prev => {
                    const copy = prev ? [...prev] : []
                    const idx = copy.findIndex(x => x.title === title)
                    if (idx != -1)
                        copy.splice(idx, 1)
                    if (copy.length === 0)
                        setOpen(false)
                    return copy
                })
            },
            customElements: customElements,
            closeOnMinimize: closeOnMinimize
        }}>
            {children}
        </SidePanelContext.Provider>
    )
}