import { createContext, useContext, useEffect, useState } from "react";
import { SystemEnvContextInterface, UserEnvContextInterface } from "./EnvContextInterface";
import { AppConfigurationClient } from '@azure/app-configuration'
import { activeUser, readAccessToken, useAuthContext, userFirmAccess } from "../Auth";
import { useQueryCurrentUserCompany } from "../api/query/useQueryCurrentUserCompany";
import useInterval from "../hooks/useInterval";
import { useLocation } from "react-router";
import { AppContext } from "./AppContextProvider";

type EnvFilterConfigurationType = {
    contentType: string
    key: string
    label: string
    lastModified: Date
    value: EnvFilterType
}
type ParameterType = {
    [key: string]: string
}

type EnvFilterType = {
    id: string
    desctiption: string
    enabled: boolean
    conditions: {
        client_filters: {
            name: string
            parameters: ParameterType
        }[]
    }
}
type KeyValueType = {
    key: string
    value: boolean
}
type StoredUserEnvType = {
    useBeta: boolean | undefined
    displaySanity: boolean | undefined
}
const getFeatureFlags = async (): Promise<EnvFilterConfigurationType[]> => {
    const client = new AppConfigurationClient(process.env.REACT_APP_APP_CONFIG_KEY)
    const featureFlags = await client.listConfigurationSettings()
    const resultList: EnvFilterConfigurationType[] = []
    for await (const flag of featureFlags) {
        const typed = JSON.parse(flag.value) as EnvFilterType
        resultList.push({
            contentType: flag.contentType,
            key: flag.key,
            label: flag.label,
            lastModified: new Date(flag.lastModified),
            value: typed,
        })
    }
    return resultList
}

const SystemEnvContext = createContext<SystemEnvContextInterface>(undefined)
const UserEnvContext = createContext<UserEnvContextInterface>(undefined)

export const SystemEnvContextProvider = ({ children }: { children }) => {

    const [configSettings, setConfigSettings] = useState<EnvFilterConfigurationType[]>([])
    const checkFlags = async () => {
        const list = await getFeatureFlags()
        // console.log({list})
        setConfigSettings(list)
    }

    useEffect(() => {
        checkFlags()
    }, [])
    useInterval(checkFlags, 1000 * 60 * 3)

    const filterParameters = <T extends unknown>(id: string, featureName: string, key: string) => {
        const feature = configSettings?.find(x => x?.value?.id === id)?.value?.conditions?.client_filters?.find(x => x.name === featureName)
        // console.log({feature})
        if (!feature) return null
        const param = feature?.parameters[key]
        return param != undefined ? param as T : null
        // const param = feature?.parameters.(x => x[key])
        // return param
    }

    return <SystemEnvContext.Provider
        value={{
            systemEnvLoaded: configSettings?.length > 0,
            altinnDisabled: configSettings?.find(x => x?.value?.id === "altinnActionsDisabled")?.value?.enabled,
            ansakoDisabled: configSettings?.find(x => x?.value?.id === "ansakoActionsDisabled")?.value?.enabled,
            forceUpdateAtilSjekklisteCounter: filterParameters<number>("forceUpdateAtilSjekklisteCounter", "CounterFilter", "counter") || -1,
            billingEnabled: configSettings?.find(x => x?.value?.id === "billing")?.value?.enabled,
            altinn3Enabled: configSettings?.find(x => x?.value?.id === "altinn3")?.value?.enabled,
            atilMarkdownEnabled: configSettings?.find(x => x?.value?.id === "atilMarkdownEnabled")?.value?.enabled,
        }}
    >
        {children}
    </SystemEnvContext.Provider>
}

export const UserEnvContextProvider = ({ children }: { children }) => {
    const { state } = useAuthContext()
    const storageKey = "useEnvSettings"
    const appContext = useContext(AppContext)
    const [isBetaUser, setIsBetaUser] = useState(false)
    const [isSuperUser, setIsSuperUser] = useState(false)
    const [storedSettings, setStoredSettings] = useState<StoredUserEnvType>({ useBeta: undefined, displaySanity: false})

    useEffect(() => {
        const stored = localStorage.getItem(storageKey)
        if (stored) {
            const parsed = JSON.parse(stored)
            setStoredSettings(parsed)
        }
        // return () => {
        //     localStorage.setItem(storageKey, JSON.stringify(storedSettings))
        // }
    }, [])
    useEffect(() => {
        const asAsync = async () => {
            const currentUser = await activeUser()
            if (currentUser?.access_token) {
                const firmAccess = userFirmAccess(currentUser)
                if (firmAccess) {
                    const isBeta = firmAccess.CustomerUserRoles.includes("182")
                    setIsSuperUser(currentUser["role"] === "superUser" || firmAccess.CustomerExternalId === "200005")
                    setIsBetaUser(isBeta)
                    const useBeta = isBeta && storedSettings?.useBeta !== false
                    setStoredSettings({ ...storedSettings, useBeta: useBeta })
                } else {
                    setStoredSettings({ ...storedSettings, useBeta: false })
                    setIsBetaUser(false)
                }
            }
        }
        asAsync()
    }, [appContext?.getUser, state?.user?.access_token])

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(storedSettings))
    }, [storedSettings])
    // const envEnabledByUserCompany = (feature: EnvFilterType) : boolean => {
    //     if (!userCompany) return false
    //     const { email, externalId, organisationNumber } = userCompany
    //     const enabled = feature.conditions.client_filters?.some(clientFilter => {
    //         // console.log("clientFilter", clientFilter.parameters)
    //         // Access the parameters object directly
    //         switch (clientFilter.name) {
    //           case "Bruker-filter":
    //             // Assuming `parameters.users` is an array of emails
    //             return clientFilter.parameters.users?.includes(email);
    //           case "Orgnr-filter":
    //             // Assuming `parameters.organisasjoner` is an array of organisation numbers
    //             return clientFilter.parameters.companies?.includes(organisationNumber);
    //           case "ExternalId-filter":
    //             // Assuming `parameters.externalIds` is an array of external IDs
    //             return clientFilter.parameters.externalIds?.includes(externalId);
    //           default:
    //             return false;
    //         }
    //     })
    //     // console.log("enabled", enabled)
    //     return enabled
    // }
    return <UserEnvContext.Provider
        value={{
            betaUser: isBetaUser,
            isSuperUser: isSuperUser,
            useBeta: storedSettings?.useBeta,
            setUseBeta: (value: boolean) => {
                setStoredSettings({ ...storedSettings, useBeta: value })
            },
            displaySanity: isSuperUser && storedSettings?.displaySanity,
            setDisplaySanity: (value: boolean) => {
                setStoredSettings({ ...storedSettings, displaySanity: value })
            }
        }}
    >
        {children}
    </UserEnvContext.Provider>
}

export const useSystemEnvContext = () => {
    const context = useContext(SystemEnvContext)
    if (!context) {
        throw new Error('useSystemEnvContext must be used within a EnvContextProvider')
    }
    return context
}
export const useUserEnvContext = () => {
    const context = useContext(UserEnvContext)
    if (!context) {
        throw new Error('useUserEnvContext must be used within a EnvContextProvider')
    }
    return context
}