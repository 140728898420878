import {
  FirmAccess,
  User,
  UserManager,
  UserManagerSettings,
  createAuthContext,
  parseUserProfileFCString
} from "@arkitektbedriftene/fe-lib/oidc";
import { RouterProvider, useLocation } from 'react-router-dom';
import { router } from './Router';
import './api/FetchIntercept';
import { AltinnContextProvider } from './contexts/AltinnContextProvider';
import { SidePanelContextProvider } from './contexts/SidePanelContextProvider';
import { TrackingProvider, useTracking } from "./Tracking";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { tokenToAbUser } from "./utils/tokenToAbUser";
import { Ab } from "./interfaces/abTypes";
import { UserEnvContextProvider } from "./contexts/EnvContextProvider";
const authServer = process.env.REACT_APP_SECURITY_SERVER
const idPortenServer = process.env.REACT_APP_IDPORTEN_SERVER
const idPortenClientId = process.env.REACT_APP_IDPORTEN_CLIENT_ID
const idPortenClientSecret = process.env.REACT_APP_IDPORTEN_CLIENT_SECRET

export const impersonateAsUserKey = `oidc.user:${authServer}:ImpersonateMAKSClient`
export const difiTokenEndpoint = idPortenServer + "/token"
const baseUrl = window.location.origin //`${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""  }`;

const oidcConfig = {
  authority: authServer ?? "",
  client_id: "MAKSClient",
  redirect_uri: `${baseUrl}/authCallback`,
  silent_redirect_uri: `${baseUrl}/authCallback`,
  response_type: "code",
  scope: "openid profile datareader ",
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
} as UserManagerSettings;


const altinnOidcConfig = {
  authority: idPortenServer,
  client_id: idPortenClientId,
  redirect_uri: `${baseUrl}/altinn/authCallback`,
  silent_redirect_uri: `${baseUrl}/altinn/authCallback`,
  response_type: "code",
  scope: "openid profile altinn:rolesandrights.read altinn:reportees altinn:instances.read altinn:roledefinitions.read altinn:instances.write",
  acr_values: "idporten-loa-high",
  code_challenge_method: "S256",
  loadUserInfo: false,
  // revokeTokensOnSignout: true,
  client_secret: idPortenClientSecret,
  post_logout_redirect_uri: baseUrl + "/",
} as UserManagerSettings;

//Ikke i bruk for øyeblikket, men om vi ønsker å lage to tilgangsnivåer
const altinnBasicAccessOidcConfig = {
  authority: idPortenServer,
  client_id: idPortenClientId,
  redirect_uri: `${baseUrl}/altinn/authCallback`,
  silent_redirect_uri: `${baseUrl}/altinn/authCallback`,
  response_type: "code",
  scope: "openid profile altinn:reportees altinn:instances.read altinn:instances.write",
  acr_values: "idporten-loa-high",
  code_challenge_method: "S256",
  loadUserInfo: false,
  // revokeTokensOnSignout: true,
  client_secret: idPortenClientSecret,
  post_logout_redirect_uri: baseUrl + "/"
} as UserManagerSettings;

export const readAccessToken = (access_token: string): Ab.Token => {
  const jwt = jwtDecode<Ab.Token>(access_token)
  return jwt
}


export const userManager = new UserManager(oidcConfig);
const auth = createAuthContext(userManager);
export const { useAuthContext, useAuthState, useSigninCallback } = auth;

export const altinnUserManager = new UserManager(altinnOidcConfig);
altinnUserManager.startSilentRenew()
export const altinnAuth = createAuthContext(altinnUserManager);
// export const { useAuthContext, useAuthState, useSigninCallback } = auth;
export const userFirmAccess = (user: User) : FirmAccess => {
  try{
    const jwt = readAccessToken(user?.access_token)
    return parseUserProfileFCString(jwt.fc)[0]
  }catch(e){
    console.error(e)
  }
  return null
}
export const activeUser = async (): Promise<User> => {
  const adminUser = localStorage.getItem(impersonateAsUserKey)
  if (adminUser) {
    const user = JSON.parse(adminUser)
    return user
  }
  return await userManager.getUser()
}

export const removeImpersonation = async () => {
  localStorage.removeItem(impersonateAsUserKey)
}

export const isImpersonation = () => {
  return localStorage.getItem(impersonateAsUserKey) != null
}

export const logoutAltinn = async () => {
  // const user = await altinnUserManager.getUser()
  await altinnUserManager.removeUser()
  await altinnUserManager.signoutRedirect({ post_logout_redirect_uri: baseUrl + "/" })
}

const Auth = () => {

  return (
    <TrackingProvider>
      <auth.AuthProvider>
        <altinnAuth.AuthProvider>
          <UserEnvContextProvider>
            <AltinnContextProvider>
              <SidePanelContextProvider>
                <RouterProvider router={router} />
              </SidePanelContextProvider>
            </AltinnContextProvider>
          </UserEnvContextProvider>
        </altinnAuth.AuthProvider>
      </auth.AuthProvider>
    </TrackingProvider>
  );
}

export default Auth;


export const LocationTracker = () => {
  const location = useLocation();
  const { trackPageView } = useTracking();

  useEffect(() => {
    if (location.pathname.includes("authCallback")) {
      return;
    }
    // Full url with protocol, hostname, port, pathname, and search
    const url = `${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`;
    trackPageView(url);
  }, [location.pathname, location.search, trackPageView]);
  return null;
};