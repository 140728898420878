

import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import { useEffect, useRef, useState } from "react";
const useExceptionEvent = (reactPlugin: ReactPlugin, exception?: IExceptionTelemetry, eventData?: any|null) => {
    const plugin = reactPlugin 
    useEffect(() => {
        if(exception) plugin.trackException(exception, eventData)
    }, [exception, eventData])
    return (exception: IExceptionTelemetry, eventData: any|null) => {
        if(exception instanceof Error){
            plugin.trackException({exception: exception}, eventData)
        }else{
            plugin.trackException({exception: new Error(exception as any)}, eventData)
        }
        
    }
};
export default useExceptionEvent;