import { ReactNode, createContext, useContext, useMemo } from "react";

type TrackingEventCategories = "Checklist" | "Comment" | "Init";

type TrackingEvent = {
  category: TrackingEventCategories;
  action: string;
  name?: string;
  value?: number;
};

type TrackingContextType = {
  trackEvent: (track: () => TrackingEvent) => void;
  setUserId: (userId: string) => void;
  trackPageView: (url: string, pageTitle?: string) => void;
};

const TrackingContext = createContext<TrackingContextType | null>(null);

const matomoSetUserId = (userId: string) => {
  try {
    // biome-ignore lint/suspicious/noExplicitAny: Can't be bothered to make a type
    (window as any)._paq.push(["setUserId", userId]);
  } catch (error) {
    console.error("Error setting Matomo user id", error);
  }
};

const matomoTrackEvent = (event: TrackingEvent) => {
  try {
    const me = ["trackEvent", event.category, event.action] as [
      "trackEvent",
      string,
      string,
      string?,
      number?,
      {
        [key: string]: string;
      }?,
    ];
    if (event.name) {
      me.push(event.name);
    }
    if (event.value) {
      me.push(event.value);
    }
    // biome-ignore lint/suspicious/noExplicitAny: Can't be bothered to make a type
    (window as any)._paq.push(me);
  } catch (error) {
    console.error("Error tracking Matomo event", error);
  }
};

const matomoTrackPageView = (url: string, pageTitle?: string) => {
  try {
    // biome-ignore lint/suspicious/noExplicitAny: Can't be bothered to make a type
    (window as any)._paq.push(["setCustomUrl", url]);
    // biome-ignore lint/suspicious/noExplicitAny: Can't be bothered to make a type
    (window as any)._paq.push(["setDocumentTitle", pageTitle]);
    // biome-ignore lint/suspicious/noExplicitAny: Can't be bothered to make a type
    (window as any)._paq.push(["trackPageView"]);
  } catch (error) {
    console.error("Error tracking Matomo page view", error);
  }
};

export const TrackingProvider = ({ children }: { children: ReactNode }) => {
  const context = useMemo(
    () => ({
      trackEvent: (track: () => TrackingEvent) => {
        try {
          const res = track();
          console.log("Tracking event", res);
          matomoTrackEvent(res);
        } catch (error) {}
      },
      setUserId: (userId: string) => {
        matomoSetUserId(userId);
      },
      trackPageView: (url: string, pageTitle?: string) => {
        setTimeout(() => {
          matomoTrackPageView(url, pageTitle);
        });
      },
    }),
    [],
  );

  return (
    <TrackingContext.Provider value={context}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const trackingContext = useContext(TrackingContext);
  if (!trackingContext) {
    throw new Error("useTracking must be used within a TrackingProvider");
  }
  return trackingContext;
};