const _getUrlParameter = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)', 'i').exec(window.location.href);
    if (results == null) {
        const path = window.location.pathname
        if(!path || path.length <= 1) return null
        if(name.toLowerCase() == "qpid"){
            let parts = path.split("/")
            if(parts[0] == "") parts.splice(0, 1)
            if(parts[0] == "prosjekt" && parts.length > 1)
                return parts[1]
            return 0
        }
    }
    else {
        return results[1] || 0;
    }
}

const _setUrlParameter = function (key, value) {
    let params = new URLSearchParams(window.location.search);
    params.set(key, value);
    window.location.search = params.toString();
}
const _setUrlParameters = function (objOfParams) {
    let searchParams = new URLSearchParams(window.location.search);
    let newParams = ""
    for(const [key, value] of Object.entries(objOfParams)){
        searchParams.set(key, value as string)
    }
    let href = window.location.pathname;
    href+=`?`+searchParams.toString()
    window.history.pushState({path: href}, "", href)
}
const _splitUrlParams = () => {
        let paramStr = window.location.search.slice(1);
        paramStr = paramStr.split("#")[0]
        const paramArr = paramStr.split("&");
        let parts = [];
        if (paramArr != undefined) {
            paramArr.forEach((part) => parts.push(part))
        }
        if (parts != undefined && parts.length > 0 && parts[0] != "") return parts;
        return undefined;
}
const _removeParamsFromUrl = (list) => {
    if(list && Array.isArray(list) && list.length){
        let params = _splitUrlParams()
        if(params && Array.isArray(params)){
            list.map(key => {
                const match = params.findIndex(x => x.includes(key))
                if(match != -1) 
                    params.splice(match, 1)
            })
            _buildUrlParams(params)
        }

    }
}
const _buildUrlParams = (parts) => {
    if (parts && Array.isArray(parts)) {
        const path = window.location.pathname;
        let paramString = parts.length > 0 ? "?" : "";
        parts.map((p, index) => {
            paramString += p;
            if (index != parts.length - 1) paramString += "&"
        })
        window.history.pushState({path: path+paramString}, "", path+paramString)
    }
}

const _getFileName = (filename) => {
    var splitted = filename.split(".");
    var namePart = splitted.slice(0, splitted.length - 1);
    return namePart.join(".")
}

const _getFileNameFromPath = (path) => {
    if (path == null) {
        return null;
    }
    var encoded = _decodeUrl(path);
    var splitted = encoded.split("/");
    return splitted[splitted.length - 1];
}

const _getFileExtention = (filename) => {
    var splitted = filename.split(".");
    return splitted[splitted.length - 1].toUpperCase();
}

const _decodeUrl = (path) => {
    return decodeURIComponent(path);
}



const _getDataTypeFromFunctionName = (functionName) => {
    if (functionName != undefined) {
        switch (functionName) {
            case "text":
            case "textLarger":
                return "Tekst";
            case "email":
                return "Epostadresse";
            case "postnr":
                return "4 siffer";
            case "numericOnly":
                return "Tall";
            case "boligNr":
                return "Formatet H1234";
            case "date":
                return "Dato dd.mm.yyy";
            case "orgnr":
                return "Organisasjonsnummer, 11 siffer";
        }
    }
}

const _sliceCodeValue = (value) => {
    if (value == undefined)
        return;
    let hasprefix = value.indexOf("_");
    if (hasprefix != -1)
        return value.slice(2);
    return value;
}

const _makeid = (length) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

const _hash = (valueToHash) => {
    let hashValue = 0, i, chr;
    const check = String(valueToHash);


    if (check === "0") return String(hashValue);
    for (i = 0; i < check.length; i++) {
        chr = valueToHash.charCodeAt(i);
        hashValue = ((hashValue << 5) - hashValue) + chr;
        hashValue |= 0; // Convert to 32bit integer
    }
    return String(hashValue);
}

const _randomString = (length) => {
    return Math.random().toString(36).substring(length || 7);
}

const _sleep = async (ms) => {
    return new Promise(r => setTimeout(r, ms))
}
const  _newGuid = () =>  crypto.randomUUID()
  
export { _getUrlParameter as getUrlParameter };
export { _setUrlParameter as setUrlParameter };
export { _setUrlParameters as setUrlParameters };
export { _splitUrlParams as splitUrlParams };
export { _buildUrlParams as buildUrlParams };
export { _removeParamsFromUrl as removeParamsFromUrl };
export { _getFileName as getFileName };
export { _getFileExtention as getFileExtention };
export { _decodeUrl as decodeUrl };
export { _getFileNameFromPath as getFileNameFromPath };
export { _getDataTypeFromFunctionName as getDataTypeFromFunctionName };
export { _sliceCodeValue as sliceCodeValue };
export { _makeid as makeId };
export { _hash as hash };
export { _randomString as randomString };
export { _sleep as sleep };
export { _newGuid as newGuid }
