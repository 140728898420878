import { Altinn } from "../interfaces/SharedTypes";
import AbApi from "./AbApi";
import Altinn3Api from "./Altinn3Api";
import AltinnApi from "./AltinnApi";
import HubFileApi from "./HubFileApi";
import MaksSokHubApi from "./MaksSokHubApi";
import { pingMaksSok } from "./query/usePingMaksSok";

const maksSokApiServer = process.env.REACT_APP_MAKS_SOK_API_SERVER
class Api{
    private static thisInstance: Api
    maksSokApiServer = maksSokApiServer
    private constructor(settings: Altinn.Common.Settings){
        this.altinn = new AltinnApi(settings)
        this.altinn3 = new Altinn3Api()
        this.ab = new AbApi(false)
        this.maksSokHub = new MaksSokHubApi(this.ab)
        this.maksSok = new AbApi(true)
        
        this.hubFile = new HubFileApi(this.ab)

    }
    static instantiate = (settings: Altinn.Common.Settings) : Api => {
        if(Api.thisInstance) return Api.thisInstance
        Api.thisInstance = new Api(settings)
        return Api.thisInstance
    }
    static instance = () => Api.thisInstance
    static maksSokApiServer = maksSokApiServer
    altinn: AltinnApi
    altinn3: Altinn3Api
    maksSokHub: MaksSokHubApi
    maksSok: AbApi
    hubFile: HubFileApi
    ab: AbApi
    testCookieSession = async () : Promise<boolean> => {
        const res = await pingMaksSok()
        try{
            if(res?.ok) return true
            return false;
        }catch(err){
            return false
        }
        
    }
}
export default Api