import { getUrlParameter } from '../utilities/utilityFunc';
import AppStore from '../stores/AppStore'
import $ from 'jquery'
// import {default as newApi } from '../../api/Api'
const handlerUrl = process.env.REACT_APP_MAKS_SOK_API_SERVER
const handlerPath = handlerUrl + "/Templates/MAKS10/Soknad/Handlers/"
// const handlerPath = "../../../../Templates/MAKS10/Soknad/Handlers/";
const handlers = {
    file: handlerPath + "FileHandler.ashx",
    log: handlerPath + "SoknadLogHandler.ashx",
    common: handlerPath + "CommonHandler.ashx",
    validate: handlerPath + "ValidateHandler.ashx",
    customArticle: handlerPath + "CustomArticleHandler.ashx",
    projectList: handlerPath + "GetProjectsHandler.ashx",
    selectSoknad: handlerPath + "GetSoknadOnProjectHandler.ashx",
    appStore: handlerPath + "AppStoreBuilder.ashx",
    sjekklister: handlerPath + "SjekklisteHandler.ashx",
    fixMessage: handlerPath + "FixLocalValidationMessage.ashx",
    contentNamesAndIds: handlerPath + "GetContentNamesAndIds.ashx",

}

let formDataOptions = {
    contentType: false,
    processData: false,
    mimeType: 'multipart/form-data',
    url: handlerPath + "FileHandler.ashx",
    type: 'POST'
};

class Api {

    action = (action) => (
        {
            params: (params) => (
                {
                    method: (method) => (
                        {
                            data: (data) => (
                                {
                                    success: (successCallback) => (
                                        {
                                            error: (errorCallback) => {
                                                let ajax = {}
                                                let apiOperation = "";
                                                //Need to use special options
                                                if (action == "file") {
                                                    Object.assign(ajax, formDataOptions);
                                                    ajax.url = handlers.file
                                                } else if (action === "log") {
                                                    ajax.url = handlers.log;
                                                    ajax.type = method;
                                                    //Default 
                                                } else {
                                                    ajax.url = handlers.common
                                                    ajax.type = method;
                                                }
                                                // let nid = getUrlParameter("nid") || null
                                                // let serviceCode = 0
                                                // let serviceCodeEdition = 0
                                                //Typically used parameters in all calls
                                                // if(!nid && AppStore.store?.nidEnv){
                                                //     nid = AppStore.store?.nidEnv.nid
                                                //     serviceCode = AppStore.store?.nidEnv.serviceCode
                                                //     serviceCodeEdition = AppStore.store?.nidEnv.serviceCodeEdition
                                                // }
                                                // if (nid == undefined || nid == "" || nid == 0 && AppStore.commonData != undefined && AppStore.commonData.NodeId != undefined)
                                                //     nid = AppStore.commonData.NodeId
                                                let qpid = getUrlParameter("QPID");

                                                if (method == "GET") {
                                                    apiOperation = "get"
                                                } else {
                                                    apiOperation = "update"
                                                }

                                                if (data == undefined) {
                                                    data = {}
                                                }

                                                // //Only if not using FileHandler
                                                if (action != "file") {
                                                    Object.assign(data, {
                                                        // nid: nid,
                                                        // serviceCode: serviceCode,
                                                        // serviceCodeEdition: serviceCodeEdition,
                                                        action: action,
                                                        apiOperation: apiOperation
                                                    })
                                                    if (qpid != undefined) {
                                                        Object.assign(data, {
                                                            qpid: qpid
                                                        });
                                                    }
                                                    if (params != null) {
                                                        if (Array.isArray(params)) {
                                                            Object.assign(data, {
                                                                params: params
                                                            })
                                                        } else if (typeof params === "object") {
                                                            Object.assign(data,
                                                                params
                                                            )
                                                        }

                                                    }
                                                }

                                                //If not already specified and it is not a FileHandler call
                                                if (!data.hasOwnProperty("nid") && action != "file") {
                                                    data.nid = window["nid"]
                                                }

                                                //If FileHandler call
                                                if (action == "file") {
                                                    ajax.data = data;
                                                    if (params != null) {
                                                        if (typeof params === "object") {
                                                            Object.assign(ajax, params)
                                                        }

                                                    }
                                                    //Typical post
                                                } else if (method == "POST") {
                                                    ajax.data = JSON.stringify(data)

                                                    //Everything else,-> GET
                                                } else {
                                                    ajax.data = data;
                                                }
                                                // ajax.beforSend= function(xhr){
                                                //     xhr.withCredentials= true
                                                // }
                                                //console.log(ajax)
                                                ajax.success = successCallback;
                                                ajax.error = errorCallback;
                                                var newOptions = Object.assign({}, ajax, {
                                                    xhrFields: {
                                                        withCredentials: true
                                                    }
                                                })
                                                $.ajax(newOptions)
                                            }
                                        }
                                    )
                                }
                            )
                        }
                    )
                }
            )
        }
    )

    getAttachment = (url, filename, errorCallback, optionalQpid, optionalNid) => {
        const nid = optionalNid || getUrlParameter("nid");
        const nidparam = nid != undefined ? '&nid=' + nid : ""
        const qpid = optionalQpid || getUrlParameter("qpid");
        var req = new XMLHttpRequest();
        req.open("GET", handlers.common + `?apiOperation=GET&action=getBlob&url=${url}&qpid=${qpid}${nidparam}`, true);
        req.responseType = "blob";
        req.withCredentials = true;
        req.onload = function (event) {
            if (req.status == 200) {
                var blob = req.response;

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);

                var fName = "fil.pdf";
                var disposition = req.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1] && filename == undefined) {
                        fName = matches[1].replace(/['"]/g, '');
                    }
                }
                link.download = filename || fName;
                link.click();
            } else {
                errorCallback("Kunne ikke hente vedlegget. " + url);
            }

        };
        req.onerror = function (event) {
            console.log(event)
        }
        req.send();
    }
    getAttachmentFile = async (url, filename, errorCallback, optionalQpid, optionalNid) => {
        const nid = optionalNid || getUrlParameter("nid");
        const nidparam = nid != undefined ? '&nid=' + nid : ""
        const qpid = optionalQpid || getUrlParameter("qpid");
        return new Promise((resolve, reject) => {
            var req = new XMLHttpRequest();
            req.open("GET", handlers.common + `?apiOperation=GET&action=getBlob&url=${url}&qpid=${qpid}${nidparam}`, true);
            req.responseType = "blob";
            req.withCredentials = true;
            req.onload = function (event) {
                if (req.status == 200) {
                    var blob = req.response;
                    resolve(new File([blob], filename, { type: blob.type }))
                } else {
                    errorCallback("Kunne ikke hente vedlegget. " + url);
                }

            };
            req.onerror = function (event) {
                reject(event)
            }
            req.send();

        })


    }

    getInfoArticle = () => (

        {
            params: (params) => (
                {
                    data: (data) => (
                        {
                            success: (success) => (
                                {
                                    error: (error) => {
                                        //let faqIdent = params.faqId != undefined ? `$faqId=${params.faqId}` : `faqGuidStr=${params.faqGuidStr}`
                                        let options = {};
                                        options.url = handlers.customArticle + "?" + params;
                                        options.type = "GET";
                                        options.data = data;
                                        options.success = success;
                                        options.error = error;
                                        options.xhrFields = {
                                            withCredentials: true
                                        }
                                        $.ajax(options);
                                    }
                                }
                            )
                        }
                    )
                }
            )
        }
    )

    setReadArticle = () => (
        {
            params: (params) => (
                {
                    data: (data) => (
                        {
                            success: (success) => (
                                {
                                    error: (error) => {
                                        let options = {};
                                        options.url = handlers.customArticle + "?" + params;
                                        options.type = "GET";
                                        options.data = data;
                                        options.success = success;
                                        options.error = error;
                                        options.xhrFields = {
                                            withCredentials: true
                                        }
                                        $.ajax(options);
                                    }
                                }
                            )
                        }
                    )
                }
            )
        }
    )


    getTimeline = () => (
        {
            data: (data) => (
                {
                    success: (success) => (
                        {
                            error: (error) => {
                                let options = {};
                                options.url = handlerPath + "TimelineHandler.ashx";
                                options.type = "GET";
                                options.data = data;
                                options.success = success;
                                options.error = error;
                                options.xhrFields = {
                                    withCredentials: true
                                }
                                $.ajax(options);
                            }
                        }
                    )
                }
            )
        }
    )

    validateSoknad = (nid) => (
        {
            success: (success) => (
                {
                    error: (error) => {
                        let options = {};
                        options.url = `${handlers.validate}?nid=${nid}&qpid=${getUrlParameter("qpid")}`
                        options.type = "GET";
                        options.data = {};
                        options.success = success;
                        options.error = error;
                        options.xhrFields = {
                            withCredentials: true
                        }
                        $.ajax(options);
                    }
                }
            )
        }
    )


    saveDistributionList = () => (
        {
            data: (data) => (
                {
                    success: (success) => (
                        {
                            error: (error) => {
                                let options = {};
                                options.url = handlerPath + "DistribusjonsHandler.ashx?qpid=" + getUrlParameter("QPID") + "&nid=" + getUrlParameter("nid");
                                options.type = "POST";
                                options.data = JSON.stringify(data);
                                options.success = success;
                                options.error = error;
                                options.xhrFields = {
                                    withCredentials: true
                                }
                                $.ajax(options);
                            }
                        }
                    )
                }
            )
        }
    )
    xmlToJson = () => (
        {
            data: (data) => (
                {
                    success: (success) => (
                        {
                            error: (error) => {
                                let options = {};
                                options.url = handlerPath + "XmltoJson.ashx";
                                options.type = "POST";
                                options.data = JSON.stringify(data);
                                options.success = success;
                                options.error = error;
                                options.xhrFields = {
                                    withCredentials: true
                                }
                                $.ajax(options);
                            }
                        }
                    )
                }
            )
        }
    )

    importFromAltinn = (data) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: handlerPath + "ImportSoknad.ashx",
                type: "POST",
                data: JSON.stringify({ obj: data }),
                xhrFields: {
                    withCredentials: true
                },
                success: (data, status, jqHxr) => {
                    resolve(data);
                },
                error: (jqHxr, textStatus, errorThrown) => {
                    reject(jqHxr);
                }
            })
        })
    }

    sendSupportEmail = (logObject) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: "../../../../Templates/MAKS10/Soknad/HubAB/LogHandler.ashx",
                type: "POST",
                data: JSON.stringify(logObject),
                xhrFields: {
                    withCredentials: true
                },
                success: (data, status, jqHxr) => {
                    resolve(data);
                },
                error: (jqHxr, textStatus, errorThrown) => {
                    reject(jqHxr);
                }
            })
        })
    }

    newProject = async (projectModel) => {
        return await fetch(handlerPath + "NewProjectHandler.ashx", {
            method: "post",
            body: JSON.stringify(projectModel),
            credentials: 'include'
        })
            .then((response) => response)

    }

    buildLog = () => (
        {
            params: (params) => (
                {
                    error: (error) => (
                        {
                            data: (data) => {
                                return ({
                                    "params": params,
                                    "error": error,
                                    "data": data
                                })
                            }
                        }
                    )
                }
            )
        }
    )

    // getProjectList = async (urlSearchParams) => {
    //     var url = handlers.projectList;
    //     return await fetch(url + urlSearchParams, {
    //         credentials: 'include'
    //     })
    // }
    // getProjectListContent = async (model) => {
    //     var url = handlers.projectList;
    //     return await fetch(url, {
    //         method: "POST",
    //         body: JSON.stringify(model),
    //         credentials: 'include'
    //     })
    // }
    getSoknadSelectList = async (urlSearchParams) => {
        var url = handlers.selectSoknad;
        return await fetch(url + urlSearchParams, {
            credentials: 'include'
        })
    }
    // getContentNamesAndIds = async (ids) => {
    //     var url = handlers.contentNamesAndIds;
    //     return await fetch(url + ids, {
    //         credentials: 'include'
    //     })
    // }
    // getAppStore = async (qpid, nid) => {
    //     var url = handlers.appStore;
    //     let params = qpid ? `?qpid=${qpid}` : ""
    //     if (nid) params += `&nid=${nid}`
    //     return await fetch(url + params, {
    //         credentials: 'include'
    //     })
    // }
    // sjekkliste = async (query) => {
    //     return fetch(handlers.sjekklister + query, {
    //         credentials: 'include'
    //     })
    // }
    fixValidationMessage = async (validationObject, currentNodeId = 0) => {
        return await fetch(handlers.fixMessage, {
            method: "post",
            credentials: 'include',
            body: JSON.stringify({
                currentNodeId: currentNodeId,
                validationModel: validationObject
            })
        })
            .then((response) => response.json())
            .catch((err) => err)

    }

}


export default new Api();